import React, { useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Panel, PanelBody, PanelHeader } from '../../../../components/panel/panel.jsx';
import { AppSettings } from '../../../../config/app-settings.js';
import { EditTenant, GetTenant } from '../../../../services/redux/tenantSlice.js';
import { setTenant } from '../../../../services/redux/userSlice.js';
import { CommunityTenantType, EducationalInstitutionTenantType, HealthInstitutionTenantType, HotelTenantType, HouseboatsTenantType, PrivateNeighborhoodTenantType } from '../../../../utils/generalConstants.js';

const TenantInfo = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const tenant = useSelector(state => state.tenantReducer);
    const globalData = useSelector(state => state.appReducer);
    const query = new URLSearchParams(window.location.search);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        // console.log("user.selectedTenant.id")
        // console.log(user.selectedTenant.id)
        dispatch(GetTenant({ authToken: user.authToken, params: { id: user.selectedTenant.id } })).then(res => console.log(res));
    }, []);

    useEffect(() => {
        // console.log("user.selectedTenant.id")
        // console.log(user.selectedTenant.id)
        dispatch(GetTenant({ authToken: user.authToken, params: { id: user.selectedTenant.id } })).then(res => console.log(res));
    }, [user.selectedTenant]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        let requestData = {
            "id": user.selectedTenant.id,
            "tenantTypeId": (data.tenantTypeId != undefined) ? data.tenantTypeId : user.selectedTenant.tenantTypeId,
            "name": data.name,
            "fiscalIdentifier": data.fiscalIdentifier,
            "email": data.email,
            "phoneNumber": data.phoneNumber,
            "stateOrRegionId": null,
            "countryId": (data.countryId != "") ? data.countryId : null,
            "zipCode": (data.zipCode != "") ? data.zipCode : null,
            "timeZone": data.timeZone,
            "settings": data.settings && {
                "areaMaxLevel": (data.settings?.areaMaxLevel != undefined) ? data.settings.areaMaxLevel : tenant.selectedTenant.settings.areaMaxLevel,
                "assetsPerRoomm": (data.settings?.assetsPerRoomm != undefined) ? data.settings.assetsPerRoomm : tenant.selectedTenant.settings.assetsPerRoomm,
                "serviceTaskModule": (data.settings?.serviceTaskModule != undefined) ? data.settings.serviceTaskModule : tenant.selectedTenant.settings.serviceTaskModule,
                "routineTaskModule": (data.settings?.routineTaskModule != undefined) ? data.settings.routineTaskModule : tenant.selectedTenant.settings.routineTaskModule
            }
        }

        await dispatch(EditTenant({ authToken: user.authToken, body: requestData })).then((response) => {
            dispatch(setTenant(requestData));
            response && response.payload && response.payload.data &&
                history.push("/account");
        });
    };

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    const isAdmin = user?.selectedTenant?.userName === "asistente.wartun@gmail.com" || user?.selectedTenant?.userName === "wartunapp@gmail.com" || user?.selectedTenant?.userName === "emenoguera@gmail.com";
    return (

        <div>
            <ol className="breadcrumb float-xl-end" hidden>
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active">{t('common.tenant_info')}</li>
            </ol>
            <h1 className="page-header">{t('common.tenant_info')} <small>complete los campos obligatorios</small></h1>

            {!tenant.isFetching && tenant.selectedTenant &&
                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t("common.general_data")}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="name" {...register("name", {
                                                required: true,
                                                value: tenant.selectedTenant.name
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Nombre
                                            </label>
                                            {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                        </div>

                                        {(isAdmin) &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="tenantTypeId" {...register("tenantTypeId", {
                                                    required: true,
                                                    value: user.selectedTenant.tenantTypeId
                                                })}>
                                                    <option key={CommunityTenantType} value={CommunityTenantType}>{t("tenant_types.community")}</option>
                                                    <option key={PrivateNeighborhoodTenantType} value={PrivateNeighborhoodTenantType}>{t("tenant_types.private_neighborhood")}</option>
                                                    <option key={HotelTenantType} value={HotelTenantType}>{t("tenant_types.hotel")}</option>
                                                    <option key={EducationalInstitutionTenantType} value={EducationalInstitutionTenantType}>{t("tenant_types.educational_institution")}</option>
                                                    <option key={HealthInstitutionTenantType} value={HealthInstitutionTenantType}>{t("tenant_types.health_institution")}</option>
                                                    <option key={HouseboatsTenantType} value={HouseboatsTenantType}>{t("tenant_types.houseboats")}</option>
                                                    <option key={CommunityTenantType} value={CommunityTenantType}>{t("tenant_types.other")}</option>
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t("common.type")}
                                                </label>
                                                {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="fiscalIdentifier" {...register("fiscalIdentifier", {
                                                required: true,
                                                value: tenant.selectedTenant.fiscalIdentifier
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Identificador Fiscal
                                            </label>
                                            {errors.name && <span className='invalid-feedback'>This field is required</span>}
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px" + (errors.description ? ' is-invalid' : '')} id="email" {...register("email", {
                                                required: true,
                                                value: tenant.selectedTenant.email,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                correo electrónico
                                            </label>
                                            {errors.description && <span className='invalid-feedback'>This field is required</span>}
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="phoneNumber" {...register("phoneNumber", {
                                                required: false,
                                                value: tenant.selectedTenant.phoneNumber
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Teléfono
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="text" className="form-control fs-15px" id="zipCode" {...register("zipCode", {
                                                required: false,
                                                value: tenant.selectedTenant.zipCode,
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Código Postal
                                            </label>
                                        </div>

                                        {globalData.countries &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="countryId" {...register("countryId", {
                                                    required: true,
                                                    value: tenant.selectedTenant.countryId,
                                                })}>
                                                    <option></option>
                                                    {globalData.countries.map(r => <option key={r.id} value={r.id}>{r.NormalizeName}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    País
                                                </label>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <input type="text" className={"form-control fs-15px" + (errors.name ? ' is-invalid' : '')} id="timeZone" {...register("timeZone", {
                                                required: false,
                                                value: tenant.selectedTenant.timeZone
                                            })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                Zona Horaria
                                            </label>
                                        </div>

                                        {isAdmin &&
                                            <>
                                                <div className="form-floating mb-15px">
                                                    <input type="number" className="form-control fs-15px" id="areaMaxLevel" {...register("settings.areaMaxLevel", {
                                                        required: true,
                                                        value: tenant.selectedTenant.settings?.areaMaxLevel,
                                                    })} />
                                                    <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                        Nivel Máximo de Área
                                                    </label>
                                                </div>

                                                <div className="form-check mb-15px">
                                                    <input type="checkbox" className="form-check-input" id="assetsPerRoomm" {...register("settings.assetsPerRoomm", {
                                                        required: false,
                                                        value: tenant.selectedTenant.settings?.assetsPerRoomm,
                                                    })} />
                                                    <label className="form-check-label fs-13px" htmlFor="assetsPerRoomm">
                                                        Activos por Habitación
                                                    </label>
                                                </div>

                                                <div className="form-check mb-15px">
                                                    <input type="checkbox" className="form-check-input" id="serviceTaskModule" {...register("settings.serviceTaskModule", {
                                                        required: false,
                                                        value: tenant.selectedTenant.settings?.serviceTaskModule,
                                                    })} />
                                                    <label className="form-check-label fs-13px" htmlFor="serviceTaskModule">
                                                        Módulo de Tareas de Servicio
                                                    </label>
                                                </div>

                                                <div className="form-check mb-15px">
                                                    <input type="checkbox" className="form-check-input" id="routineTaskModule" {...register("settings.routineTaskModule", {
                                                        required: false,
                                                        value: tenant.selectedTenant.settings?.routineTaskModule,
                                                    })} />
                                                    <label className="form-check-label fs-13px" htmlFor="routineTaskModule">
                                                        Módulo de Tareas de Rutina
                                                    </label>
                                                </div>
                                            </>
                                        }

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                            {tenant.error && <div className='text-center'>
                                                <span className='text-danger'>Ha ocurrido un problema: {tenant.errorMessage}</span></div>}
                                        </div>

                                    </form>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    {isAdmin &&
                        <div className="col-xl-3">
                            {JSON.stringify(tenant.selectedTenant)}
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default TenantInfo;