import React, { useContext, useEffect, useState } from 'react';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledButtonDropdown } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { GetDashboardTasksData } from '../../../services/redux/dashboardSlice';
import { FullListUsers } from '../../../services/redux/userSlice';
import { CreateWorkOrder, FinishWorkOrder, SendToValidateWorkOrder } from '../../../services/redux/workOrderSlice';
import { HotelTenantType, MaintenanceTaskPriorityLevel, MaintenanceTaskStates, MaintenanceTaskTypes, PrivateNeighborhoodTenantType } from "../../../utils/generalConstants";
import { Loader } from '../../widget/loader';
import { Panel, PanelBody, PanelHeader } from './../../../components/panel/panel.jsx';
import { FullListAreas } from '../../../services/redux/areaSlice.js';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice.js';
import { renderAreasDropdownItems } from '../../../components/app/area/common.js';
import { ExportToPdfWorkOrders } from '../workorder/pdf.js';
// import { isEmptyObject } from 'jquery';



const MaintenanceTasksDashboard = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);

    const { t } = useTranslation('common');

    //Todo: Check if this is necessary
    const query = new URLSearchParams(window.location.search)

    const user = useSelector(state => state.userReducer);
    const dashboardTasks = useSelector(state => state.dashboardReducer);
    const tasks = useSelector(state => state.maintenanceTaskReducer);
    const workOrder = useSelector(state => state.workOrderReducer)
    const areas = useSelector(state => state.areaReducer)
    const functionalUnits = useSelector(state => state.functionalUnitReducer)
    const rooms = useSelector(state => state.roomReducer)

    const [pendingMaintenanceTasks, setPendingMaintenanceTasks] = useState([]);
    const [startedWorkOrders, setStartedWorkOrders] = useState([]);
    const [toValidateWorkOrders, setToValidateWorkOrders] = useState([]);
    const [finishedWorkOrders, setFinishedWorkOrders] = useState([]);
    const [dayWorkOrders, setDayWorkOrders] = useState([]);

    const [maintenanceTaskStateId, setMaintenanceTaskStateId] = useState(query.get('stateid') ? [query.get('stateid'), query.get('statename')] : []);
    const [maintenanceTaskTypeId, setMaintenanceTaskTypeId] = useState([]);
    const [maintenanceTaskPriorityLevel, setMaintenanceTaskPriorityLevel] = useState([]);
    const [agentId, setAgentId] = useState([]);
    const [areaId, setAreaId] = useState([]);
    const [functionalUnitId, setFunctionalUnitId] = useState([]);
    const [roomId, setRoomId] = useState([]);

    const [modalAddTask, setModalAddTask] = useState(false);
    const [modalSelectedTask, setModalSelectedTask] = useState(null);
    const [modalWorkOrderPdf, setModalWorkOrderPdf] = useState(false);

    useEffect(() => {
        // context.handleSetAppSidebarNone(false);
        // context.handleSetAppHeaderNone(false);
        getDashboardTasksData();

        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })

    }, []);

    useEffect(() => {

        dashboardTasks?.tasksData?.pendingMaintenanceTasks &&
            setPendingMaintenanceTasks(dashboardTasks.tasksData.pendingMaintenanceTasks
                .filter((task) => (
                    (!maintenanceTaskTypeId[0] || task.taskTypeId === maintenanceTaskTypeId[0]) &&
                    (!maintenanceTaskPriorityLevel[0] || task.priorityLevelId === maintenanceTaskPriorityLevel[0]) &&
                    ((!agentId[0] || task.assignedId === agentId[0]) || (agentId[0] === "EMPTY" && task.assignedId === "00000000-0000-0000-0000-000000000000")) &&
                    (!areaId[0] || task.areaId === areaId[0] || task.parentAreaId === areaId[0] || task.parentParentAreaId === areaId[0]) &&
                    (!functionalUnitId[0] || task.functionalUnitId === functionalUnitId[0]) &&
                    (!roomId[0] || task.roomId === roomId[0])
                ))
            )

        dashboardTasks?.tasksData?.startedWorkOrders &&
            setStartedWorkOrders(dashboardTasks.tasksData.startedWorkOrders
                .filter((task) => (
                    (!maintenanceTaskTypeId[0] || task.taskTypeId === maintenanceTaskTypeId[0]) &&
                    (!maintenanceTaskPriorityLevel[0] || task.priorityLevelId === maintenanceTaskPriorityLevel[0]) &&
                    (!agentId[0] || task.assignedId === agentId[0]) &&
                    (!areaId[0] || task.areaId === areaId[0] || task.parentAreaId === areaId[0] || task.parentParentAreaId === areaId[0]) &&
                    (!functionalUnitId[0] || task.functionalUnitId === functionalUnitId[0]) &&
                    (!roomId[0] || task.roomId === roomId[0])
                ))
            )

        dashboardTasks?.tasksData?.toValidateWorkOrders &&
            setToValidateWorkOrders(dashboardTasks.tasksData.toValidateWorkOrders
                .filter((task) => (
                    (!maintenanceTaskTypeId[0] || task.taskTypeId === maintenanceTaskTypeId[0]) &&
                    (!maintenanceTaskPriorityLevel[0] || task.priorityLevelId === maintenanceTaskPriorityLevel[0]) &&
                    (!agentId[0] || task.assignedId === agentId[0]) &&
                    (!areaId[0] || task.areaId === areaId[0] || task.parentAreaId === areaId[0] || task.parentParentAreaId === areaId[0]) &&
                    (!functionalUnitId[0] || task.functionalUnitId === functionalUnitId[0]) &&
                    (!roomId[0] || task.roomId === roomId[0])
                ))
            )

        dashboardTasks?.tasksData?.finishedWorkOrders &&
            setFinishedWorkOrders(dashboardTasks.tasksData.finishedWorkOrders
                .filter((task) => (
                    (!maintenanceTaskTypeId[0] || task.taskTypeId === maintenanceTaskTypeId[0]) &&
                    (!maintenanceTaskPriorityLevel[0] || task.priorityLevelId === maintenanceTaskPriorityLevel[0]) &&
                    (!agentId[0] || task.assignedId === agentId[0]) &&
                    (!areaId[0] || task.areaId === areaId[0] || task.parentAreaId === areaId[0] || task.parentParentAreaId === areaId[0]) &&
                    (!functionalUnitId[0] || task.functionalUnitId === functionalUnitId[0]) &&
                    (!roomId[0] || task.roomId === roomId[0])
                ))
            )

        dashboardTasks?.tasksData?.dayWorkOrders &&
            setDayWorkOrders(dashboardTasks.tasksData.dayWorkOrders
                .filter((task) => (
                    (!maintenanceTaskTypeId[0] || task.taskTypeId === maintenanceTaskTypeId[0]) &&
                    (!maintenanceTaskPriorityLevel[0] || task.priorityLevelId === maintenanceTaskPriorityLevel[0]) &&
                    (!agentId[0] || task.assignedId === agentId[0]) &&
                    (!areaId[0] || task.areaId === areaId[0] || task.parentAreaId === areaId[0] || task.parentParentAreaId === areaId[0]) &&
                    (!functionalUnitId[0] || task.functionalUnitId === functionalUnitId[0]) &&
                    (!roomId[0] || task.roomId === roomId[0])
                ))
            )


    }, [dashboardTasks, maintenanceTaskTypeId, maintenanceTaskPriorityLevel, agentId, areaId, functionalUnitId, roomId]);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    const getDashboardTasksData = async () => {

        let requestData = {
            "tenantId": user.selectedTenant.id,
        };

        await dispatch(GetDashboardTasksData({ authToken: user.authToken, body: requestData })).then((response) => {
        });
    };

    const startMaintenanceTask = async (data) => {
        let requestData = {
            "maintenanceTaskId": modalSelectedTask.id,
            "scheduleId": modalSelectedTask.scheduleId,
            "scheduledDate": modalSelectedTask.scheduledDate,
            "operatorId": data.operatorId,
            "createdById": user.selectedTenant.agentId,
            "tenantId": user.selectedTenant.id,
        }

        toggleModal('modalAddTask');
        await dispatch(CreateWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            getDashboardTasksData();
        });
    }

    const sendToValidate = async (workOrder) => {
        let requestData = {
            "id": workOrder.id,
            "tenantId": user.selectedTenant.id,
            "LogTrackBody": "Orden de trabajo enviada para validar",//messageModal,
            "AgentId": user.selectedTenant.agentId,
        }

        //console.log(requestData);
        await dispatch(SendToValidateWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            dispatch(GetDashboardTasksData({ authToken: user.authToken, body: requestData })).then((response) => {
            });
        });
    }

    const finishWorkOrder = async (workOrder) => {
        let requestData = {
            "id": workOrder.id,
            "tenantId": user.selectedTenant.id,
            "LogTrackBody": "Orden de trabajo finalizada",//messageModal,
            "AgentId": user.selectedTenant.agentId,
        }

        //console.log(requestData);
        await dispatch(FinishWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            dispatch(GetDashboardTasksData({ authToken: user.authToken, body: requestData })).then((response) => {
            });
        });
    }

    const toggleModal = (name, maintenanceTask) => {
        switch (name) {
            case 'modalAddTask':
                reset();
                setModalSelectedTask(maintenanceTask);
                setModalAddTask(!modalAddTask);
                break;
            case 'modalWorkOrderPdf':
                setModalWorkOrderPdf(!modalWorkOrderPdf);
                break;
            default:
                break;
        }
    }

    const handleOnChange = () => {

    }

    return (
        <Loader isLoading={dashboardTasks.isFetching || tasks.isFetching || workOrder.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    {/* <li className="breadcrumb-item"><Link to="/task/dashboard">{t('dashboard.title_tasks')}</Link></li> */}
                    <li className="breadcrumb-item active">{t('menu.tasks_dashboard')}</li>
                </ol>
                <h1 
                // className="page-header"
                >{t('menu.tasks_dashboard')} de plan<small></small></h1>

                <div style={{ minHeight: '100vh', backgroundImage: 'url(../assets/img/cover/cover-scrum-board.png)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundSize: '360px', backgroundPosition: 'right bottom' }}>

                    <div className="d-flex align-items-center mb-2">
                        <div className="ms-auto">
                            <Link to="/task/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                            {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                <Link to="/task/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                            }
                            <button hidden className="btn btn-danger m-3px" onClick={() => toggleModal('modalWorkOrderPdf')}>Exportar a PDF</button>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                        {/* <h1 className="page-header mb-0">
                            Scrum Board
                        </h1> */}

                        <div className="page-header mb-0">
                            {/* <div className="d-block d-md-flex align-items-center mb-3">
                                <div className="d-flex"> */}
                            <div className="d-block align-items-center mb-1 mb-sm-3">
                                <div className="flex-column">

                                    {areas.fullList && areas.fullList.length > 0 &&
                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} {t('common.area')} <b className="caret"></b><span className="badge bg-default text-gray-800 ms-5px">{areaId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {/* {areas.fullList.map((area, index) =>
                                                    <DropdownItem onClick={() => setAreaId([area.id, area.name])} key={area.id}>{area.name}</DropdownItem>
                                                )} */}
                                                {renderAreasDropdownItems(areas.fullList, setAreaId, null)}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setAreaId([])} key="ALL">Todas</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    }

                                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                        <DropdownToggle color="white" caret>
                                            {t('common.filters_by')} tipo <b className="caret"></b><span className="badge bg-default text-gray-800 ms-5px">{maintenanceTaskTypeId[1]}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {MaintenanceTaskTypes.map((type, index) =>
                                                <DropdownItem onClick={() => setMaintenanceTaskTypeId([type.id, t("task.type_" + type.NormalizeName)])} key={type.id}>{t("task.type_" + type.NormalizeName)}</DropdownItem>
                                            )}
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => setMaintenanceTaskTypeId([])} key="ALL">Todos</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>

                                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                        <DropdownToggle color="white" caret>
                                            {t('common.filters_by')} prioridad <b className="caret"></b><span className="badge bg-default text-gray-800 ms-5px">{maintenanceTaskPriorityLevel[1]}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {MaintenanceTaskPriorityLevel.map((type, index) =>
                                                <DropdownItem onClick={() => setMaintenanceTaskPriorityLevel([type.id, t("common.priority_level_" + type.NormalizeName)])} key={type.id}>{t("common.priority_level_" + type.NormalizeName)}</DropdownItem>
                                            )}
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => setMaintenanceTaskPriorityLevel([])} key="ALL">Todos</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>

                                    <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0" hidden>
                                        <DropdownToggle color="white" caret>
                                            {t('common.filters_by')} estado <b className="caret"></b><span className="badge bg-gray ms-5px">{maintenanceTaskStateId[1]}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {MaintenanceTaskStates.map((state, index) =>
                                                <DropdownItem onClick={() => setMaintenanceTaskStateId([state.id, t("task.state_" + state.NormalizeName)])} key={state.id}>{t("task.state_" + state.NormalizeName)}</DropdownItem>
                                            )}
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => setMaintenanceTaskStateId([])} key="ALL">Todos</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>

                                    {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                        user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                        user.fullList &&
                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} usuario <b className="caret"></b><span className={agentId[0] != "EMPTY" ? "badge bg-gray ms-5px" : "badge bg-yellow text-black ms-5px"}>{agentId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => setAgentId(["EMPTY", "Sin Asignar"])} key="EMPTY">Sin Asignar</DropdownItem>
                                                <DropdownItem divider />
                                                {user.fullList.map((user, index) =>
                                                    (user.role.normalizedName.includes("MANAGER") ||
                                                        user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                        user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                    <DropdownItem onClick={() => setAgentId([user.agent.id, user.agent.fullName + " (" + t("roles.rol_" + user.role.normalizedName) + ")"])} key={user.agent.id}>{user.agent.fullName + " (" + t("roles.rol_" + user.role.normalizedName) + ")"}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setAgentId([])} key="ALL">Todos</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    }

                                    {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                                        functionalUnits.fullList &&
                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} {t('common.functional_unit')} <b className="caret"></b><span className="badge bg-default text-gray-800 ms-5px">{functionalUnitId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {functionalUnitId != "" &&
                                                    <DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
                                                }
                                                {functionalUnits.fullList.map((functionalUnit, index) =>
                                                    <DropdownItem onClick={() => setFunctionalUnitId([functionalUnit.id, functionalUnit.number])} key={functionalUnit.id}>{functionalUnit.number}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    }

                                    {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                                        rooms.fullList &&
                                        <UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
                                            <DropdownToggle color="white" caret>
                                                {t('common.filters_by')} {t('common.room')} <b className="caret"></b><span className="badge bg-default text-gray-800 ms-5px">{roomId[1]}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {roomId != "" &&
                                                    <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                                }
                                                {rooms.fullList.map((room, index) =>
                                                    <DropdownItem onClick={() => setRoomId([room.id, room.name])} key={room.id}>{room.name}</DropdownItem>
                                                )}
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* <div className="ms-auto">
                        <button onClick={() => toggleModal('modalAddTask')} className="btn btn-success btn-sm btn-rounded px-3 rounded-pill"><i className="fa fa-plus me-1"></i> {t("task.add_task")}</button>
                    </div> */}

                    </div>


                    {dashboardTasks.tasksData &&
                        <div className="row">
                            {user.selectedTenant.agentRoles &&
                                (user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                    user.selectedTenant.agentRoles.toUpperCase() == "COUNCILOR" ||
                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                <div className="col-xl-3 col-lg-6">
                                    <Panel>
                                        <PanelHeader noButton={true}>{t("task.scheduled_tasks")} ({pendingMaintenanceTasks.length})</PanelHeader>
                                        <PanelBody className="list-group list-group-flush rounded-bottom overflow-hidden p-0">

                                            <div>
                                                {pendingMaintenanceTasks.length != 0 ?
                                                    pendingMaintenanceTasks.map((maintenanceTask, index) =>
                                                        <div key={index} className="list-group-item d-flex border-top-0">
                                                            <div className="me-3 fs-16px">
                                                                <i className="fa fa-tasks text-gray-500 fa-fw"></i>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <div className="mb-1 fs-12px">
                                                                    <div className="text-gray-600 flex-1">{moment(maintenanceTask.scheduledDate).format("DD/MM/YYYY")}</div>
                                                                </div>
                                                                <Link to={"/task/detail?id=" + maintenanceTask.id} className="fs-14px lh-12 mb-2px fw-bold text-dark">{maintenanceTask.description}</Link>
                                                                <div className="mb-1 fs-12px">
                                                                    {maintenanceTask.responsibleFullName &&
                                                                        <div className="text-dark flex-1">{maintenanceTask.responsibleFullName}</div>
                                                                    }
                                                                </div>
                                                                <div className="mb-1">
                                                                    <span className="badge bg-default text-gray-800">{t("task.type_" + maintenanceTask.typeNormalizedName)}</span>
                                                                    {new Date(maintenanceTask.scheduledDate) < new Date() &&
                                                                        <span className="badge bg-red ms-2px">{t("common.expired")}</span>
                                                                    }
                                                                    {!maintenanceTask.responsibleFullName &&
                                                                        <span className="badge bg-yellow text-black ms-2px">Sin asignar</span>
                                                                    }
                                                                </div>

                                                                {maintenanceTask.autoStart ?
                                                                    <span className="badge bg-green">Inicio Automático</span>
                                                                    :
                                                                    <div className="mb-1 mt-10px">
                                                                        <button className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill" onClick={() => toggleModal('modalAddTask', maintenanceTask)}>{t("common.start")}</button>
                                                                    </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div>
                                                        <div className="list-group-item d-flex border-top-0">
                                                            <div className="me-3 fs-12px">
                                                                <i className="fa fa-info text-gray-500 fa-fw"></i>
                                                            </div>
                                                            <div className="mb-1 fs-12px">
                                                                <div className="flex-fill">
                                                                    <div className="text-gray-600 flex-1">{t("task.tasks_scheduled_not_found")}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div hidden>
                                                <div className="list-group-item d-flex border-top-0">
                                                    <div className="me-3 fs-16px">
                                                        <i className="far fa-question-circle text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="fs-14px lh-12 mb-2px fw-bold text-dark">Enable open search</div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">#29949 opened yesterday by Terry</div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <span className="badge bg-blue me-1">docs</span>
                                                            <span className="badge bg-success">feature</span>
                                                        </div>
                                                        <hr className="mb-10px bg-gray-600" />
                                                        <div className="d-flex align-items-center mb-5px">
                                                            <div className="fs-12px me-2 text-dark fw-bold">
                                                                Task (2/3)
                                                            </div>
                                                            <div className="progress h-5px w-100px mb-0 me-2">
                                                                <div className="progress-bar progress-bar-striped" style={{ width: '66%' }}></div>
                                                            </div>
                                                            <div className="fs-10px fw-bold">66%</div>
                                                            <div className="ms-auto">
                                                                <Link to="/extra/scrum-board" className="btn btn-outline-default text-gray-600 btn-xs rounded-pill fs-10px px-2" data-bs-toggle="collapse" data-bs-target="#todoBoard">
                                                                    collapse
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-1">
                                                            <div className="collapse show" id="todoBoard">
                                                                <div className="form-check mb-1">
                                                                    <input type="checkbox" onChange={handleOnChange} className="form-check-input" id="customCheck1" checked />
                                                                    <label className="form-check-label" htmlFor="customCheck1">create ui for autocomplete</label>
                                                                </div>
                                                                <div className="form-check mb-1">
                                                                    <input type="checkbox" onChange={handleOnChange} className="form-check-input" id="customCheck2" checked />
                                                                    <label className="form-check-label" htmlFor="customCheck2">integrate jquery autocomplete with ui</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input type="checkbox" onChange={handleOnChange} className="form-check-input" id="customCheck3" />
                                                                    <label className="form-check-label" htmlFor="customCheck3">backend search return as json data</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to="/extra/scrum-board" className="list-group-item list-group-item-action d-flex">
                                                    <div className="me-3 fs-16px">
                                                        <i className="far fa-question-circle text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="fs-14px lh-12 mb-2px fw-bold text-dark">Investigate adding markdownlint</div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">#29919 opened 9 days ago by xMediaKron</div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <span className="badge bg-light text-dark me-1">build</span>
                                                            <span className="badge bg-indigo">v5</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/extra/scrum-board" className="list-group-item list-group-item-action d-flex">
                                                    <div className="me-3 fs-16px">
                                                        <i className="far fa-question-circle text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="fs-14px lh-12 mb-2px fw-bold text-dark">Add a "Submit a Resource" form</div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">#29916 opened 9 days ago by Wasbbok</div>
                                                        </div>
                                                        <div className="mb-1 d-flex align-items-center">
                                                            <div className="me-2"><span className="badge bg-success">enhancement</span></div>
                                                            <div className="d-flex">
                                                                <div className="widget-img widget-img-xs rounded-circle bg-inverse me-n2" style={{ backgroundImage: 'url(../assets/img/user/user-1.jpg)' }}>
                                                                </div>
                                                                <div className="widget-img widget-img-xs rounded-circle bg-inverse me-n2" style={{ backgroundImage: 'url(../assets/img/user/user-2.jpg)' }}>
                                                                </div>
                                                                <div className="widget-img widget-img-xs rounded-circle bg-inverse me-n2" style={{ backgroundImage: 'url(../assets/img/user/user-3.jpg)' }}>
                                                                </div>
                                                                <div className="widget-icon widget-icon-xs rounded-circle bg-muted text-white fs-10px">
                                                                    +2
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/extra/scrum-board" className="list-group-item list-group-item-action d-flex">
                                                    <div className="me-3 fs-16px">
                                                        <i className="far fa-question-circle text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="fs-14px lh-12 mb-2px fw-bold text-dark">Custom control border color missing on focus</div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">#29796 opened 29 days ago by mdo</div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <span className="badge bg-pink">docs</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to="/extra/scrum-board" className="list-group-item list-group-item-action d-flex">
                                                    <div className="me-3 fs-16px">
                                                        <i className="far fa-question-circle text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="fs-14px lh-12 mb-2px fw-bold text-dark">New design for corporate page</div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">#29919 opened 19 days ago by sean</div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <span className="badge bg-light text-dark me-1">design</span>
                                                            <span className="badge bg-primary">v5</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                        </PanelBody>
                                    </Panel>
                                </div>
                            }

                            <div className="col-xl-3 col-lg-6">
                                <Panel>
                                    <PanelHeader noButton={true}>{t("task.tasks_started")} ({startedWorkOrders.length})</PanelHeader>
                                    <PanelBody className="list-group list-group-flush rounded-bottom overflow-hidden p-0">

                                        <div>
                                            {startedWorkOrders.length != 0 ?
                                                startedWorkOrders.map((workOrder, index) =>
                                                    <div key={index} className="list-group-item d-flex border-top-0">
                                                        <div className="me-3 fs-16px">
                                                            <i className="fa fa-tools text-gray-500 fa-fw"></i>
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="mb-1 fs-12px">
                                                                <div className="text-gray-600 flex-1">{t("common.started_on")} {moment(workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</div>
                                                            </div>
                                                            <Link to={"/workorder/detail?id=" + workOrder.id} className="fs-14px lh-12 mb-2px fw-bold text-dark">{workOrder.description}</Link>
                                                            <div className="mb-1 fs-12px">
                                                                <div className="text-gray-600 flex-1">{workOrder.operatorFullName}</div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <span className="badge bg-default text-gray-800">{t("task.type_" + workOrder.typeNormalizedName)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <div>
                                                    <div className="list-group-item d-flex border-top-0">
                                                        <div className="me-3 fs-12px">
                                                            <i className="fa fa-info text-gray-500 fa-fw"></i>
                                                        </div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="flex-fill">
                                                                <div className="text-gray-600 flex-1">{t("task.tasks_started_not_found")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </PanelBody>
                                </Panel>
                            </div>
                            <div className="col-xl-3 col-lg-6">
                                <Panel>
                                    <PanelHeader noButton={true}>{t("task.tasks_to_validate")} ({toValidateWorkOrders.length})</PanelHeader>
                                    <PanelBody className="list-group list-group-flush rounded-bottom overflow-hidden p-0">
                                        {toValidateWorkOrders.length != 0 ?
                                            toValidateWorkOrders.map((workOrder, index) =>
                                                <div key={index} className="list-group-item d-flex border-top-0">
                                                    <div className="me-3 fs-16px">
                                                        <i className="fa fa-tools text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">{t("common.sent_on")} {moment(workOrder.sendToValidateOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</div>
                                                        </div>
                                                        <Link to={"/workorder/detail?id=" + workOrder.id} className="fs-14px lh-12 mb-2px fw-bold text-dark">{workOrder.description}</Link>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">{workOrder.operatorFullName}</div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <span className="badge bg-default text-gray-800">{t("task.type_" + workOrder.typeNormalizedName)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div>
                                                <div className="list-group-item d-flex border-top-0">
                                                    <div className="me-3 fs-12px">
                                                        <i className="fa fa-info text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="mb-1 fs-12px">
                                                        <div className="flex-fill">
                                                            <div className="text-gray-600 flex-1">{t("task.tasks_to_validate_not_found")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </PanelBody>
                                </Panel>
                            </div>
                            <div className="col-xl-3 col-lg-6" hidden>
                                <Panel>
                                    <PanelHeader noButton={true}>{t("task.tasks_finished")} ({finishedWorkOrders.length})</PanelHeader>
                                    <PanelBody className="list-group list-group-flush rounded-bottom overflow-hidden p-0">
                                        {finishedWorkOrders.length != 0 ?
                                            finishedWorkOrders.map((workOrder, index) =>
                                                <div key={index} className="list-group-item d-flex border-top-0">
                                                    <div className="me-3 fs-16px">
                                                        <i className="fa fa-tools text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">{t("common.finished_on")} {moment(workOrder.finishedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</div>
                                                        </div>
                                                        <Link to={"/workorder/detail?id=" + workOrder.id} className="fs-14px lh-12 mb-2px fw-bold text-dark">{workOrder.description}</Link>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="text-gray-600 flex-1">{workOrder.operatorFullName}</div>
                                                        </div>
                                                        <div className="mb-1">
                                                            <span className="badge bg-default text-gray-800">{t("task.type_" + workOrder.typeNormalizedName)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            <div>
                                                <div className="list-group-item d-flex border-top-0">
                                                    <div className="me-3 fs-12px">
                                                        <i className="fa fa-info text-gray-500 fa-fw"></i>
                                                    </div>
                                                    <div className="mb-1 fs-12px">
                                                        <div className="flex-fill">
                                                            <div className="text-gray-600 flex-1">{t("task.task_completed_not_found")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </PanelBody>
                                </Panel>
                            </div>

                            <div className="col-xl-3 col-lg-6" >
                                <Panel>
                                    <PanelHeader noButton={true}>Finalizadas del día ({dayWorkOrders.length})</PanelHeader>
                                    <PanelBody className="list-group list-group-flush rounded-bottom overflow-hidden p-0">

                                        <div>
                                            {dayWorkOrders.length != 0 ?
                                                dayWorkOrders.map((workOrder, index) =>
                                                    <div key={index} className="list-group-item d-flex border-top-0">
                                                        <div className="me-3 fs-16px">
                                                            <i className="fa fa-tools text-gray-500 fa-fw"></i>
                                                        </div>
                                                        <div className="flex-fill">
                                                            <div className="mb-1 fs-12px">
                                                                <div className="text-gray-600 flex-1">{t("common.finished_on")} {moment(workOrder.finishedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</div>
                                                            </div>
                                                            <Link to={"/workorder/detail?id=" + workOrder.id} className="fs-14px lh-12 mb-2px fw-bold text-dark">{workOrder.description}</Link>
                                                            <div className="mb-1 fs-12px">
                                                                <div className="text-gray-600 flex-1">{workOrder.operatorFullName}</div>
                                                            </div>
                                                            <div className="mb-1">
                                                                <span className="badge bg-default text-gray-800">{t("task.type_" + workOrder.typeNormalizedName)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <div>
                                                    <div className="list-group-item d-flex border-top-0">
                                                        <div className="me-3 fs-12px">
                                                            <i className="fa fa-info text-gray-500 fa-fw"></i>
                                                        </div>
                                                        <div className="mb-1 fs-12px">
                                                            <div className="flex-fill">
                                                                <div className="text-gray-600 flex-1">{t("task.task_completed_not_found")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </PanelBody>
                                </Panel>
                            </div>

                        </div>
                    }

                    <Modal isOpen={modalAddTask} toggle={() => toggleModal('modalAddTask')}>
                        <ModalHeader toggle={() => toggleModal('modalAddTask')}>{t("task.create_workOrder")} </ModalHeader>
                        <ModalBody>
                            {modalSelectedTask &&
                                <form onSubmit={handleSubmit(startMaintenanceTask)}>
                                    {user.fullList &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="operatorId" {...register("operatorId", {
                                                required: true,
                                                value: modalSelectedTask.assignedId,
                                            })}>
                                                <option></option>
                                                {user.fullList.map((user, index) =>
                                                    (user.role.normalizedName.includes("MANAGER") ||
                                                        user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                        user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                    <option key={index} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                )
                                                }
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t("workOrder.operator")}
                                            </label>
                                        </div>
                                    }

                                    <div className="mb-20px">
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value="Asignar" />
                                        {dashboardTasks.error && <div className='text-center'>
                                            <span className='text-danger'>{t("common.error", { message: dashboardTasks.errorMessage })}</span></div>}
                                    </div>
                                </form>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-white" onClick={() => toggleModal('modalAddTask')}>{t("common.cancel")}</button>
                        </ModalFooter>
                    </Modal>
                </div>

                <Modal size="lg" style={{ maxWidth: '80vw', width: '100%' }} isOpen={modalWorkOrderPdf} toggle={() => toggleModal('modalWorkOrderPdf')} >
                    <ModalHeader toggle={() => toggleModal('modalWorkOrderPdf')}>Orden de trabajo</ModalHeader>
                    <ModalBody>
                        <ExportToPdfWorkOrders workOrders={toValidateWorkOrders} fileName={"Month_WorkOrders.pdf"} />
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white" onClick={() => toggleModal('modalWorkOrderPdf')}>Cerrar</button>
                    </ModalFooter>
                </Modal>
            </div >
        </Loader>
    )
};

export default MaintenanceTasksDashboard;