import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { AppSettings } from '../../../config/app-settings.js';
import { DeleteFile, postfileData } from '../../../services/redux/fileSlice';
import { DeleteServiceTask, GetServiceTask } from '../../../services/redux/serviceTaskSlice';
import { FullListUsers } from '../../../services/redux/userSlice';
import { CreateWorkOrder } from '../../../services/redux/workOrderSlice';
import { FileTypes, ServiceTasksIdPending, ServiceTasksIdFinished } from '../../../utils/generalConstants';
import { Loader } from '../../widget/loader';
import { Panel, PanelBody, PanelFooter, PanelHeader } from '.././../../components/panel/panel.jsx';
import { renderLinesBreak } from '../../../utils/common.js';
import { CreateServiceWorkOrder } from '../../../services/redux/serviceWorkOrderSlice.js';

const ServiceTaskDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.serviceTaskReducer)
    const file = useSelector(state => state.fileReducer)
    const query = new URLSearchParams(window.location.search)
    const { t } = useTranslation('common');

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalAddTask, setModalAddTask] = useState(false);
    const [modalSelectedTask, setModalSelectedTask] = useState(null);
    const [modalViewDeleteTask, setModalViewDeleteTask] = useState(false);
    const [messageModal, setMessageModal] = useState(null);

    // VALIDATE Y FINISH
    // const [sweetAlertToValidate, setSweetAlertToValidate] = useState(false);
    // const [sweetAlertFinish, setSweetAlertFinish] = useState(false);

    const getTask = async () => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        //console.log(requestData)
        await dispatch(GetServiceTask({ authToken: user.authToken, params: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const startServiceTask = async (data) => {
        let requestData = {
            "serviceTaskId": task.selectedServiceTask.id,
            "operatorId": data.operatorId,
            "createdById": user.selectedTenant.agentId,
            "tenantId": user.selectedTenant.id,
        }

        //console.log(requestData);
        await dispatch(CreateServiceWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleModal('modalAddTask');
            getTask();
        });
    }

    const deleteTask = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteServiceTask({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/servicetask/list");
            toggleModal('modalViewDeleteTask')
        });
    }

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getTask();
        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                // console.log("GET FullListUsers TO TASK");
                //console.log(response);
            });
    }, []);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    const onUploadSubmit = async (data) => {

        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", task.selectedServiceTask.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'MANTENANCETASK IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getTask();
        });
    };

    const deleteFile = async (fileId) => {
        const requestDataDeleteFile = {
            "tenantId": user.selectedTenant.id,
            "id": fileId
        };

        await dispatch(DeleteFile({ authToken: user.authToken, body: requestDataDeleteFile })).then((response) => {
            getTask();
        });
    };

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalAddTask':
                reset();
                setModalSelectedTask(task.selectedServiceTask);
                setModalAddTask(!modalAddTask);
                break;
            case 'modalViewDeleteTask':
                setModalViewDeleteTask(!modalViewDeleteTask);
                break;
            default:
                break;
        }
    }

    return (
        <Loader isLoading={task.isFetching || file.isFetching}>
            <div>
                {task.selectedServiceTask &&
                    <div>
                        <ol className="breadcrumb float-xl-end" hidden>
                            <li className="breadcrumb-item"><Link to="/servicetask/dashboard">{t('menu.tasks_dashboard')}</Link></li>
                            <li className="breadcrumb-item active">{t('dashboard.title_tasks')}</li>
                            <li className="breadcrumb-item active">{task.selectedServiceTask.description}</li>
                        </ol>
                        <h1 className="page-header">{task.selectedServiceTask.description}</h1>

                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link hidden to="/servicetask/dashboard" className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                <Link to="/servicetask/dashboard" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-tasks me-1"></i> {t("menu.tasks_dashboard")}</Link>
                                <Link to="/servicetask/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                {/* {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                    <Link to="/servicetask/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                                } */}
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-xl-7">
                                <div className='row'>
                                    <div className="col-xl-12">
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex justify-content-between">
                                                        {t("common.general_data")}
                                                        {(user.selectedTenant.agentRoles.toUpperCase() === "MANAGER" ||
                                                            user.selectedTenant.agentRoles.toUpperCase() === "MAINTENANCECHIEF" ||
                                                            task.selectedServiceTask.createdById === user.selectedTenant.agentId) &&
                                                            <div>
                                                                <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill me-7px" to={{
                                                                    pathname: "/servicetask/edit",
                                                                    state: {
                                                                        serviceTask: task.selectedServiceTask
                                                                    }
                                                                }}> {t("common.edit")}</Link>
                                                                <button
                                                                    className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"
                                                                    onClick={() => toggleModal('modalAddFiles')}>
                                                                    {t("common.add_files")}
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    <p>
                                                        {t("common.description")}: <b>{task.selectedServiceTask.description}</b>
                                                        {/* {task.selectedServiceTask.stateId != ServiceTasksIdFinished && new Date(task.selectedServiceTask.scheduledDate) < new Date() &&
                                                            <span className="badge bg-red ms-5px">{t("common.expired")}</span>
                                                        } */}
                                                    </p>
                                                    {task.selectedServiceTask.detail &&
                                                        <>
                                                            <p><b>{t("common.detail")}:</b></p>
                                                            <p className='fs-20px'>{renderLinesBreak(task.selectedServiceTask.detail)}</p>
                                                        </>
                                                    }
                                                    {/**/} {task.selectedServiceTask.responsibleFullName ?
                                                        <p>
                                                            Asignada a: {task.selectedServiceTask.responsibleFullName}
                                                            {task.selectedServiceTask.autoStart &&
                                                                <span className="badge bg-green ms-5px">Inicio Automático</span>
                                                            }
                                                        </p>
                                                        :
                                                        <p>Asignada a: <span className="badge bg-yellow text-black ms-5px">Sin asignar</span></p>
                                                    } 

                                                    {task.selectedServiceTask.stateId != ServiceTasksIdFinished &&
                                                        <p>{t("common.scheduled_date")}: {moment(task.selectedServiceTask.scheduledDate).format("DD/MM/YYYY")}</p>
                                                    }
                                                    <p>{t("common.priority")}: {t("common.priority_level_" + task.selectedServiceTask.priorityLevelNormalizedName)}</p>
                                                    <p>{t("common.createdOn")}: {moment(task.selectedServiceTask.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                                    <p>{t("common.created_by")}: {task.selectedServiceTask.createdBy}</p>
                                                    {task.selectedServiceTask.areaName &&
                                                        <p>{t("common.area")}: {task.selectedServiceTask.areaName}&nbsp;<Link to={"/area/detail?id=" + task.selectedServiceTask.areaId + "&servicetaskid=" + task.selectedServiceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.area")}</Link></p>
                                                    }
                                                    {task.selectedServiceTask.functionalUnit &&
                                                        <p>{t("common.functional_unit")}: {task.selectedServiceTask.functionalUnit.number}&nbsp;<Link to={"/functionalUnit/detail?id=" + task.selectedServiceTask.functionalUnit.id + "&servicetaskid=" + task.selectedServiceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.functional_unit")}</Link></p>
                                                    }
                                                    {task.selectedServiceTask.room &&
                                                        <p>{t("common.room")}: {task.selectedServiceTask.room.name}&nbsp;<Link to={"/room/detail?id=" + task.selectedServiceTask.room.id + "&servicetaskid=" + task.selectedServiceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.room")}</Link></p>
                                                    }
                                                    {task.selectedServiceTask.asset &&
                                                        <p>{t("common.asset")}: {task.selectedServiceTask.asset.name}&nbsp;<Link to={"/asset/detail?id=" + task.selectedServiceTask.asset.id + "&servicetaskid=" + task.selectedServiceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.asset")}</Link></p>
                                                    }
                                                    {task.selectedServiceTask.workOrder &&
                                                        <>
                                                            <p><b>{t("common.work_order").toUpperCase()}</b></p>
                                                            <div className="bg-gray-300 mb-15px p-15px">
                                                                <p>Asignada a: {task.selectedServiceTask.workOrder.operatorFullName}<Link to={"/serviceworkorder/detail?id=" + task.selectedServiceTask.workOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">Ver Orden de trabajo</Link></p>
                                                                <p>{t("common.started_on")}: {moment(task.selectedServiceTask.workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}</p>
                                                                <p>Estado de la Orden de trabajo: <span className="badge bg-gray ms-5px">{t("workOrder.state_" + task.selectedServiceTask.workOrder.stateNormalizedName)}</span></p>
                                                                <p>{t("common.created_by")}: {task.selectedServiceTask.workOrder.createdBy}</p>
                                                                {/* <div className="d-flex align-items-center mb-10px">
                                                                <div className="ms-auto">
                                                                    <Link to={"/workorder/detail?id=" + task.selectedServiceTask.workOrder.id} className="btn btn-primary me-5px">Ver Orden de trabajo</Link>
                                                                </div>
                                                            </div> */}
                                                                {task.selectedServiceTask.workOrder.logTracks &&
                                                                    <div className="col-xl-12">
                                                                        {task.selectedServiceTask.workOrder.logTracks.length > 0 &&
                                                                            <Panel>
                                                                                <PanelHeader noButton={true}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        Historial
                                                                                    </div>
                                                                                </PanelHeader>

                                                                                <PanelBody>
                                                                                    {task.selectedServiceTask.workOrder.logTracks.map((log, index) =>
                                                                                        <div key={index}>
                                                                                            <p><i className="fa fa-circle-info fa-lg"></i>&nbsp;{moment(log.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}&nbsp;-&nbsp;{log.createdByFullName}</p>
                                                                                            <p>{log.body}</p>
                                                                                        </div>
                                                                                    )}
                                                                                </PanelBody>
                                                                            </Panel>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </PanelBody>
                                                {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF" ||
                                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCEOPERATOR") &&
                                                    <PanelFooter>
                                                        <div className="d-flex align-items-center">
                                                            <div className="ms-auto">
                                                                {task.selectedServiceTask.stateId === ServiceTasksIdPending &&
                                                                    <button className="btn btn-primary me-5px" onClick={() => toggleModal('modalAddTask', task.selectedServiceTask)}>{t("common.start")}</button>
                                                                }
                                                                {/* VALIDATE Y FINISH */}
                                                                {/* {task.selectedServiceTask.workOrder && task.selectedServiceTask.stateId === ServiceTasksIdExecution &&
                                                                task.selectedServiceTask.workOrder.workOrderStateId != WorkOrderStateToValidateId &&
                                                                <button className="btn btn-primary me-5px" onClick={() => toggleSweetAlert("sweetAlertToValidate")}>{t("common.sent_to_validate")}</button>
                                                            }
                                                            {task.selectedServiceTask.workOrder && ((task.selectedServiceTask.stateId === ServiceTasksIdExecution &&
                                                                !task.selectedServiceTask.requireValidation) ||
                                                                (task.selectedServiceTask.stateId === ServiceTasksIdExecution &&
                                                                    task.selectedServiceTask.workOrder.workOrderStateId === WorkOrderStateToValidateId)) &&
                                                                <button className="btn btn-primary me-5px" onClick={() => toggleSweetAlert("sweetAlertFinish")} >{t("common.finish")}</button>
                                                            } */}
                                                                {(user.selectedTenant.agentRoles.toUpperCase() === "MANAGER" ||
                                                                    task.selectedServiceTask.createdById === user.selectedTenant.agentId) &&
                                                                    <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteTask') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                                                }
                                                            </div>
                                                        </div>
                                                        {task.error && <div className='text-center mt-5px'>
                                                            <span className='text-danger'>Ha ocurrido un problema: {task.errorMessage}</span></div>}
                                                    </PanelFooter>
                                                }
                                            </Panel>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3">

                                {task.selectedServiceTask.finishOrCanceledWorkOrders.length > 0 &&
                                    <div>
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t("common.work_orders")}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {task.selectedServiceTask.finishOrCanceledWorkOrders.map((workOrder, index) =>
                                                    workOrder.id ?
                                                        <p key={index} className={workOrder.stateNormalizedName === "ACTIVE" ? "bg-gray-300 p-2px" : ""}>
                                                            <Link to={"/serviceworkorder/detail?id=" + workOrder.id} key={index} className={workOrder.stateNormalizedName === "ACTIVE" ? "bg-gray-300 p-2px" : ""}>{moment(workOrder.startedOn).format("DD/MM/YYYY")}&nbsp;{t("workOrder.state_" + workOrder.stateNormalizedName).toUpperCase()}</Link>
                                                        </p>
                                                        :
                                                        <p key={index} className={workOrder.stateNormalizedName === "ACTIVE" ? "bg-gray-300 p-2px" : ""}>{moment(workOrder.startedOn).format("DD/MM/YYYY")}&nbsp;{t("workOrder.state_" + workOrder.stateNormalizedName).toUpperCase()}</p>
                                                )}
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }

                                {task.selectedServiceTask.logTracks &&
                                    <div className="col-xl-12">
                                        {task.selectedServiceTask.logTracks.length > 0 &&
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        Historial
                                                    </div>
                                                </PanelHeader>

                                                <PanelBody>
                                                    {task.selectedServiceTask.logTracks.map((log, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-circle-info fa-lg"></i>&nbsp;{moment(log.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}&nbsp;-&nbsp;{log.createdByFullName}</p>
                                                            <p> {log.body}</p>
                                                        </div>
                                                    )}
                                                </PanelBody>
                                            </Panel>
                                        }
                                    </div>
                                }

                            </div>

                        </div>

                        <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                        {task.selectedServiceTask.taskReviewFiles &&
                            <div className='mb-10px'>
                                <Viewer files={task.selectedServiceTask.taskReviewFiles} allowDelete={false} deleteAsyncCallback={deleteFile} />
                            </div>
                        }
                        <Viewer files={task.selectedServiceTask.files} allowDelete={false} deleteAsyncCallback={deleteFile} />

                        <Modal isOpen={modalViewDeleteTask} toggle={() => toggleModal('modalViewDeleteTask')} size="md" style={{ maxWidth: '300px', width: '100%' }}>
                            <ModalHeader toggle={() => toggleModal('modalViewDeleteTask')}>Eliminar Activo</ModalHeader>
                            <ModalBody>
                                <span>¿Esta seguro que desea eliminar la tarea <b>{task.selectedServiceTask.description}</b>, creado por <b>{task.selectedServiceTask.createdBy}</b> el <b>{moment(task.selectedServiceTask.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteTask(task.selectedServiceTask.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteTask')}>No</button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={modalAddTask} toggle={() => toggleModal('modalAddTask')}>
                            <ModalHeader toggle={() => toggleModal('modalAddTask')}>{t("task.create_workOrder")} </ModalHeader>
                            <ModalBody>
                                <form onSubmit={handleSubmit(startServiceTask)}>

                                    {
                                        user.fullList &&
                                        (user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                            user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&

                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="operatorId" {...register("operatorId", {
                                                required: true,
                                                value: task.selectedServiceTask.responsibleId,
                                            })}>
                                                <option></option>
                                                {user.fullList.map((user, index) =>
                                                    (user.role.normalizedName.includes("MANAGER") ||
                                                        user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                        user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                    <option key={user.agent.id} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                )
                                                }
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t("workOrder.operator")}
                                            </label>
                                        </div>
                                    }

                                    {
                                        user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCEOPERATOR" &&
                                        <div className="form-floating mb-15px">
                                            <div className='fs-20px'>¿Está seguro que desea iniciar la tarea de servicio?</div>
                                            <input type="hidden" id="operatorId" {...register("operatorId", {
                                                required: true,
                                                value: user.selectedTenant.agentId,
                                            })} />
                                        </div>
                                    }

                                    <div className="mb-20px">
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value="Iniciar" />
                                        {task.error && <div className='text-center'>
                                            <span className='text-danger'>{t("common.error", { message: task.errorMessage })}</span></div>}
                                    </div>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-white" onClick={() => toggleModal('modalAddTask')}>{t("common.cancel")}</button>
                                {/*<button className="btn btn-primary">Create</button>*/}
                            </ModalFooter>
                        </Modal>

                    </div>
                }
            </div >
        </Loader>
    )
};

export default ServiceTaskDetail;