import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, Button } from 'reactstrap';
import { AppSettings } from '../../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { ListRoutineTasks } from '../../../services/redux/routineTaskSlice';
import RoutineTaskItem from '../../../components/app/routineTask/listItem'
import Pagination from '../../../components/app/pager/pagination'
import { Loader } from '../../widget/loader';
import { useTranslation } from "react-i18next";
import { RoutineTaskTypes, RoutineTaskStates, RoutineTaskPriorityLevel, HotelTenantType, PrivateNeighborhoodTenantType } from "../../../utils/generalConstants";
import { FullListAreas } from '../../../services/redux/areaSlice.js';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice.js';
import { renderAreasDropdownItems } from '../../../components/app/area/common.js';


const RoutineTasksList = () => {

	const dispatch = useDispatch();
	const context = useContext(AppSettings);
	const { t } = useTranslation('common');

	const query = new URLSearchParams(window.location.search)

	const user = useSelector(state => state.userReducer);
	const tasks = useSelector(state => state.routineTaskReducer);
	const areas = useSelector(state => state.areaReducer)
	const functionalUnits = useSelector(state => state.functionalUnitReducer)
	const rooms = useSelector(state => state.roomReducer)

	const [keyword, setkeyword] = useState("");
	const [areaId, setAreaId] = useState([]);
	const [functionalUnitId, setFunctionalUnitId] = useState([]);
	const [roomId, setRoomId] = useState([]);
	const [routineTaskStateId, setRoutineTaskStateId] = useState(query.get('stateid') ? [query.get('stateid'), t("task.state_" + RoutineTaskStates.filter(state => state.id == query.get('stateid'))[0].NormalizeName)] : []);
	const [priorityLevelId, setPriorityLevelId] = useState([]);
	const [agentId, setAgentId] = useState([]);
	const take = 15;

	useEffect(() => {
		context.handleSetAppSidebarNone(false);
		context.handleSetAppHeaderNone(false);
		//toggleSplit(0);

		!areas.fullList &&
			dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
				//console.log(response);
			});

		!functionalUnits.fullList &&
			dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
				//console.log(response);
			});

		!rooms.fullList &&
			dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
				//console.log(response);
			})

	}, []);

	useEffect(() => {
		toggleSplit(0)
	}, [routineTaskStateId, priorityLevelId, areaId, functionalUnitId, roomId, agentId]);

	const toggleSplit = async (skip) => {

		const requestData = {
			"tenantId": user.selectedTenant.id,
			"areaId": areaId[0],
			"functionalUnitId": functionalUnitId[0],
			"roomId": roomId[0],
			"responsibleId": agentId[0],
			"priorityLevelId": priorityLevelId[0],
			"routineTaskStateId": routineTaskStateId[0],
			"createdById": agentId[0],
			"keyword": keyword,
			"sortBy": null,
			"sortDir": true,
			"skip": skip,
			"take": take
		};

		//requestData.skip = skip;

		await dispatch(ListRoutineTasks({ authToken: user.authToken, body: requestData })).then((response) => {
			//console.log(response);
		});
	};

	const toggleSplitCallBack = useCallback(
		(skip) => {
			toggleSplit(skip);
		},
		[keyword, routineTaskStateId, priorityLevelId, areaId, functionalUnitId, roomId, agentId],
	);

	return (
		<Loader isLoading={tasks.isFetching}>
			<div>
				<ol className="breadcrumb float-xl-end" hidden>
					<li className="breadcrumb-item"><Link to="/routinetask/dashboard">{t('menu.tasks_dashboard')}</Link></li>
					<li className="breadcrumb-item active">{t('dashboard.title_tasks')}</li>
				</ol>
				<h1 
                // className="page-header"
                >{t('dashboard.title_tasks') + ' de rutina'}
					{/* {tasks && <small>{tasks.dataCountFiltered} {t('common.results_found_of')} {tasks.dataCount}</small>} */}
				</h1>

				<div className="d-flex align-items-center mb-2">
					<div className="ms-auto">
						<Link hidden to="/routinetask/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
						<Link hidden to="/routinetask/dashboard" className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
						<Link to="/routinetask/dashboard" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-tasks me-1"></i> {t("menu.tasks_dashboard")}</Link>
						{(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
							user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
							<Link to="/routinetask/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
						}
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						<InputGroup size="lg" className="mb-3">
							<Input placeholder={t("common.enter_keyword_here")} type="text" className="input-white" onKeyPress={(e) => e.key === 'Enter' && toggleSplit(0)} onChange={(text) => setkeyword(text.target.value)} />
							<Button color="primary" className="rounded-right btn-lg" onClick={() => toggleSplit(0)}><i className="fa fa-search fa-fw"></i> {t('common.search')}</Button>
							{/*<ButtonDropdown toggle={toggleSplit} hidden>*/}
							{/*	<DropdownToggle color="primary" className="btn-lg" split>*/}
							{/*		<i className="fa fa-cog fa-fw"></i>*/}
							{/*	</DropdownToggle>*/}
							{/*	<DropdownMenu>*/}
							{/*		<DropdownItem>Action</DropdownItem>*/}
							{/*		<DropdownItem>Another Action</DropdownItem>*/}
							{/*		<DropdownItem>Something else here</DropdownItem>*/}
							{/*		<DropdownItem divider />*/}
							{/*		<DropdownItem>Separated link</DropdownItem>*/}
							{/*	</DropdownMenu>*/}
							{/*</ButtonDropdown>*/}
						</InputGroup>

						{tasks.routineTasks &&
							<div>
								{/* <div className="d-block d-md-flex align-items-center mb-3">
									<div className="d-flex"> */}
								<div className="d-block align-items-center mb-1 mb-sm-3">
									<div className="flex-column">

										{areas.fullList && areas.fullList.length > 0 &&
											<UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
												<DropdownToggle color="white" caret>
													{t('common.filters_by')} {t('common.area')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{areaId[1]}</span>
												</DropdownToggle>
												<DropdownMenu>
													{/* {areas.fullList.map((area, index) =>
														<DropdownItem onClick={() => setAreaId([area.id, area.name])} key={area.id}>{area.name}</DropdownItem>
													)} */}
													{renderAreasDropdownItems(areas.fullList, setAreaId, null)}
													<DropdownItem divider />
													<DropdownItem onClick={() => setAreaId([])} key="ALL">Todas</DropdownItem>
												</DropdownMenu>
											</UncontrolledButtonDropdown>
										}

										<UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
											<DropdownToggle color="white" caret>
												{t('common.filters_by')} prioridad <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{priorityLevelId[1]}</span>
											</DropdownToggle>
											<DropdownMenu>
												{RoutineTaskPriorityLevel.map((type, index) =>
													<DropdownItem onClick={() => setPriorityLevelId([type.id, t("common.priority_level_" + type.NormalizeName)])} key={type.id}>{t("common.priority_level_" + type.NormalizeName)}</DropdownItem>
												)}
												<DropdownItem divider />
												<DropdownItem onClick={() => setPriorityLevelId([])} key="ALL">Todos</DropdownItem>
											</DropdownMenu>
										</UncontrolledButtonDropdown>

										{(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
											user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
											user.fullList &&
											<UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
												<DropdownToggle color="white" caret>
													{t('common.filters_by')} usuario <b className="caret"></b><span className="badge bg-gray ms-5px">{agentId[1]}</span>
												</DropdownToggle>
												<DropdownMenu>
													{user.fullList.map((user, index) =>
														(user.role.normalizedName.includes("MANAGER") ||
															user.role.normalizedName.includes("MAINTENANCECHIEF") ||
															user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
														<DropdownItem onClick={() => setAgentId([user.agent.id, user.agent.fullName + " (" + t("roles.rol_" + user.role.normalizedName) + ")"])} key={user.agent.id}>{user.agent.fullName + " (" + t("roles.rol_" + user.role.normalizedName) + ")"}</DropdownItem>
													)}
													<DropdownItem divider />
													<DropdownItem onClick={() => setAgentId([])} key="ALL">Todos</DropdownItem>
												</DropdownMenu>
											</UncontrolledButtonDropdown>
										}

										<UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
											<DropdownToggle color="white" caret>
												{t('common.filters_by')} estado <b className="caret"></b><span className="badge bg-gray ms-5px">{routineTaskStateId[1]}</span>
											</DropdownToggle>
											<DropdownMenu>
												{RoutineTaskStates.map((state, index) =>
													<DropdownItem onClick={() => setRoutineTaskStateId([state.id, t("task.state_" + state.NormalizeName)])} key={state.id}>{t("task.state_" + state.NormalizeName)}</DropdownItem>
												)}
												<DropdownItem divider />
												<DropdownItem onClick={() => setRoutineTaskStateId([])} key="ALL">Todos</DropdownItem>
											</DropdownMenu>
										</UncontrolledButtonDropdown>

										{user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
											functionalUnits.fullList &&
											<UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
												<DropdownToggle color="white" caret>
													{t('common.filters_by')} {t('common.functional_unit')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{functionalUnitId[1]}</span>
												</DropdownToggle>
												<DropdownMenu>
													{functionalUnitId != "" &&
														<DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
													}
													{functionalUnits.fullList.map((functionalUnit, index) =>
														<DropdownItem onClick={() => setFunctionalUnitId([functionalUnit.id, functionalUnit.number])} key={functionalUnit.id}>{functionalUnit.number}</DropdownItem>
													)}
													<DropdownItem divider />
													<DropdownItem onClick={() => setFunctionalUnitId([])} key="ALL">Todas</DropdownItem>
												</DropdownMenu>
											</UncontrolledButtonDropdown>
										}

										{user?.selectedTenant?.tenantTypeId == HotelTenantType &&
											rooms.fullList &&
											<UncontrolledButtonDropdown className="me-2 mb-2 mb-sm-0">
												<DropdownToggle color="white" caret>
													{t('common.filters_by')} {t('common.room')} <b className="caret"></b><span className="badge bg-default text-dark ms-5px">{roomId[1]}</span>
												</DropdownToggle>
												<DropdownMenu>
													{roomId != "" &&
														<DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
													}
													{rooms.fullList.map((room, index) =>
														<DropdownItem onClick={() => setRoomId([room.id, room.name])} key={room.id}>{room.name}</DropdownItem>
													)}
													<DropdownItem divider />
													<DropdownItem onClick={() => setRoomId([])} key="ALL">Todas</DropdownItem>
												</DropdownMenu>
											</UncontrolledButtonDropdown>
										}

										<div className="btn-group" hidden>
											<Link to="/extra/search" className="btn btn-white"><i className="fa fa-list"></i></Link>
											<Link to="/extra/search" className="btn btn-white"><i className="fa fa-th"></i></Link>
											<Link to="/extra/search" className="btn btn-white"><i className="fa fa-th-large"></i></Link>
										</div>
									</div>
								</div>

								{tasks.routineTasks.length != 0 ?
									<Pagination toggleSplitCallBack={toggleSplitCallBack} dataCount={tasks.dataCountFiltered} take={take}>
										<div className="result-list">
											{tasks.routineTasks.map((routineTask, index) =>
												// <RoutineTaskItem key={routineTask.id} {...routineTask}></RoutineTaskItem>
												<RoutineTaskItem key={routineTask.id} routineTask={routineTask}></RoutineTaskItem>
											)}
										</div>
									</Pagination>
									:
									<div className="alert alert-info" role="alert">
										{t("common.empty_list")}
									</div>
								}
							</div>
						}

					</div>
				</div>
			</div>
		</Loader>
	)
};

export default RoutineTasksList;