import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { AppSettings } from '../../../config/app-settings.js';
import { DeleteFile, postfileData } from '../../../services/redux/fileSlice';
import { DeleteMaintenanceTask, GetMaintenanceTask } from '../../../services/redux/maintenanceTaskSlice';
import { FullListUsers } from '../../../services/redux/userSlice';
import { CreateWorkOrder } from '../../../services/redux/workOrderSlice';
import { FileTypes, MaintenanceTasksIdPending, MaintenanceTasksIdFinished } from '../../../utils/generalConstants';
import { Loader } from '../../widget/loader';
import { Panel, PanelBody, PanelFooter, PanelHeader } from '.././../../components/panel/panel.jsx';
import { renderLinesBreak } from '../../../utils/common.js';

const MaintenanceTaskDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.maintenanceTaskReducer)
    const workOrder = useSelector(state => state.workOrderReducer)
    const file = useSelector(state => state.fileReducer)
    const query = new URLSearchParams(window.location.search)
    const { t } = useTranslation('common');

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalAddTask, setModalAddTask] = useState(false);
    const [modalSelectedTask, setModalSelectedTask] = useState(null);
    const [modalViewDeleteTask, setModalViewDeleteTask] = useState(false);
    const [messageModal, setMessageModal] = useState(null);

    // VALIDATE Y FINISH
    // const [sweetAlertToValidate, setSweetAlertToValidate] = useState(false);
    // const [sweetAlertFinish, setSweetAlertFinish] = useState(false);

    const getTask = async () => {

        const requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        //console.log(requestData)
        await dispatch(GetMaintenanceTask({ authToken: user.authToken, params: requestData })).then((response) => {
            //console.log(response);
        });
    };

    const startMaintenanceTask = async (data) => {
        let requestData = {
            "maintenanceTaskId": task.selectedMaintenanceTask.id,
            "scheduleId": task.selectedMaintenanceTask.scheduleId,
            "scheduledDate": task.selectedMaintenanceTask.scheduledDate,
            "operatorId": data.operatorId,
            "createdById": user.selectedTenant.agentId,
            "tenantId": user.selectedTenant.id,
        }

        //console.log(requestData);
        await dispatch(CreateWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
            toggleModal('modalAddTask');
            getTask();
        });
    }

    // VALIDATE Y FINISH
    // const sendToValidate = async (workOrder) => {
    //     let requestData = {
    //         "id": workOrder.id,
    //         "tenantId": user.selectedTenant.id,
    //         "LogTrackBody": "Orden de trabajo enviada para validar",//messageModal,
    //         "AgentId": user.selectedTenant.agentId,
    //     }
    //     console.log(requestData);
    //     await dispatch(SendToValidateWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
    //         getTask();
    //         toggleSweetAlert("sweetAlertToValidate");
    //     });
    // }

    // VALIDATE Y FINISH
    // const finishWorkOrder = async (workOrder) => {
    //     let requestData = {
    //         "id": workOrder.id,
    //         "tenantId": user.selectedTenant.id,
    //         "LogTrackBody": "Orden de trabajo finalizada",//messageModal,
    //         "AgentId": user.selectedTenant.agentId,
    //     }
    //     console.log(requestData);
    //     await dispatch(FinishWorkOrder({ authToken: user.authToken, body: requestData })).then((response) => {
    //         getTask();
    //         toggleSweetAlert("sweetAlertFinish");
    //     });
    // }

    const deleteTask = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteMaintenanceTask({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/task/list");
            toggleModal('modalViewDeleteTask')
        });
    }

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getTask();
        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                // console.log("GET FullListUsers TO TASK");
                //console.log(response);
            });
    }, []);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    const onUploadSubmit = async (data) => {

        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", task.selectedMaintenanceTask.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'MANTENANCETASK IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getTask();
        });
    };

    const deleteFile = async (fileId) => {
        const requestDataDeleteFile = {
            "tenantId": user.selectedTenant.id,
            "id": fileId
        };

        await dispatch(DeleteFile({ authToken: user.authToken, body: requestDataDeleteFile })).then((response) => {
            getTask();
        });
    };

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalAddTask':
                reset();
                setModalSelectedTask(task.selectedMaintenanceTask);
                setModalAddTask(!modalAddTask);
                break;
            case 'modalViewDeleteTask':
                setModalViewDeleteTask(!modalViewDeleteTask);
                break;
            default:
                break;
        }
    }

    // VALIDATE Y FINISH
    // const toggleSweetAlert = (name) => {
    //     switch (name) {
    //         case 'sweetAlertFinish':
    //             setSweetAlertFinish(!sweetAlertFinish);
    //             break;
    //         case 'sweetAlertToValidate':
    //             setSweetAlertToValidate(!sweetAlertToValidate);
    //             break;
    //         default:
    //             break;
    //     }
    // }

    return (
        <Loader isLoading={task.isFetching || file.isFetching}>
            <div>
                {task.selectedMaintenanceTask &&
                    <div>
                        <ol className="breadcrumb float-xl-end" hidden>
                            <li className="breadcrumb-item"><Link to="/task/dashboard">{t('menu.tasks_dashboard')}</Link></li>
                            <li className="breadcrumb-item active">{t('dashboard.title_tasks')}</li>
                            <li className="breadcrumb-item active">{task.selectedMaintenanceTask.description}</li>
                        </ol>
                        <h1 className="page-header">{task.selectedMaintenanceTask.description}</h1>

                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link hidden to="/task/dashboard" className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                <Link to="/task/dashboard" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-tasks me-1"></i> {t("menu.tasks_dashboard")}</Link>
                                <Link to="/task/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                {/* {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                    <Link to="/task/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                                } */}
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-xl-7">
                                <div className='row'>
                                    <div className="col-xl-12">
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex justify-content-between">
                                                        {t("common.general_data")}
                                                        {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                            user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                                            <div>
                                                                <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill me-7px" to={{
                                                                    pathname: "/task/edit",
                                                                    state: {
                                                                        maintenanceTask: task.selectedMaintenanceTask
                                                                    }
                                                                }}> {t("common.edit")}</Link>
                                                                <button
                                                                    className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"
                                                                    onClick={() => toggleModal('modalAddFiles')}>
                                                                    {t("common.add_files")}
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    <p>
                                                        {t("common.description")}: <b>{task.selectedMaintenanceTask.description}</b>
                                                        {/* {task.selectedMaintenanceTask.stateId != MaintenanceTasksIdFinished && new Date(task.selectedMaintenanceTask.scheduledDate) < new Date() &&
                                                            <span className="badge bg-red ms-5px">{t("common.expired")}</span>
                                                        } */}
                                                    </p>
                                                    {task.selectedMaintenanceTask.detail &&
                                                        <>
                                                            <p><b>{t("common.detail")}:</b></p>
                                                            <p className='fs-20px'>{renderLinesBreak(task.selectedMaintenanceTask.detail)}</p>
                                                        </>
                                                    }
                                                    {task.selectedMaintenanceTask.responsibleFullName ?
                                                        <p>
                                                            Asignada a: {task.selectedMaintenanceTask.responsibleFullName}
                                                            {task.selectedMaintenanceTask.autoStart &&
                                                                <span className="badge bg-green ms-5px">Inicio Automático</span>
                                                            }
                                                        </p>
                                                        :
                                                        <p>Asignada a: <span className="badge bg-yellow text-black ms-5px">Sin asignar</span></p>
                                                    }

                                                    {task.selectedMaintenanceTask.stateId != MaintenanceTasksIdFinished &&
                                                        <p>{t("common.scheduled_date")}: {moment(task.selectedMaintenanceTask.scheduledDate).format("DD/MM/YYYY")}</p>
                                                    }
                                                    <p>{t("common.type")}: <span className="badge bg-default text-dark ms-5px">{t("task.type_" + task.selectedMaintenanceTask.typeNormalizedName)}</span></p>
                                                    <p>{t("common.priority")}: {t("common.priority_level_" + task.selectedMaintenanceTask.priorityLevelNormalizedName)}</p>
                                                    <p>{t("task.validation_required")}: {task.selectedMaintenanceTask.requireValidation ? "Sí" : "No"}</p>
                                                    <p>{t("common.createdOn")}: {moment(task.selectedMaintenanceTask.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                                    {task.selectedMaintenanceTask.areaName &&
                                                        // <p>{t("common.area")}: {task.selectedMaintenanceTask.areaName}</p>
                                                        <p>{t("common.area")}: {task.selectedMaintenanceTask.areaName}&nbsp;<Link to={"/area/detail?id=" + task.selectedMaintenanceTask.areaId + "&taskid=" + task.selectedMaintenanceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.area")}</Link></p>
                                                    }
                                                    {task.selectedMaintenanceTask.functionalUnit &&
                                                        <p>{t("common.functional_unit")}: {task.selectedMaintenanceTask.functionalUnit.number}&nbsp;<Link to={"/functionalUnit/detail?id=" + task.selectedMaintenanceTask.functionalUnit.id + "&taskid=" + task.selectedMaintenanceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.functional_unit")}</Link></p>
                                                    }
                                                    {task.selectedMaintenanceTask.room &&
                                                        <p>{t("common.room")}: {task.selectedMaintenanceTask.room.name}&nbsp;<Link to={"/room/detail?id=" + task.selectedMaintenanceTask.room.id + "&taskid=" + task.selectedMaintenanceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.room")}</Link></p>
                                                    }
                                                    {task.selectedMaintenanceTask.asset &&
                                                        <p>{t("common.asset")}: {task.selectedMaintenanceTask.asset.name}&nbsp;<Link to={"/asset/detail?id=" + task.selectedMaintenanceTask.asset.id + "&taskid=" + task.selectedMaintenanceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.asset")}</Link></p>
                                                    }
                                                    {task.selectedMaintenanceTask.supplierName &&
                                                        <p>{t("common.supplier")}: {task.selectedMaintenanceTask.supplierName}&nbsp;<Link to={"/supplier/detail?id=" + task.selectedMaintenanceTask.supplier.id + "&taskid=" + task.selectedMaintenanceTask.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">{t("common.supplier")}</Link></p>
                                                    }
                                                    {task.selectedMaintenanceTask.estimatedCost &&
                                                        <p>{t("common.estimated_cost")}: ${task.selectedMaintenanceTask.estimatedCost}</p>
                                                    }
                                                    {task.selectedMaintenanceTask.estimatedTime &&
                                                        <p>{t("common.estimated_time")}: {task.selectedMaintenanceTask.estimatedTime} Hora/s</p>
                                                    }
                                                    {task.selectedMaintenanceTask.workOrder &&
                                                        <>
                                                            <p><b>{t("common.work_order").toUpperCase()} ACTIVA</b></p>
                                                            <div className="bg-gray-300 mb-15px p-15px">
                                                                <p>Asignada a: {task.selectedMaintenanceTask.workOrder.operatorFullName}<Link to={"/workorder/detail?id=" + task.selectedMaintenanceTask.workOrder.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px">Ver Orden de trabajo</Link></p>
                                                                <p>{t("common.started_on")}: {moment(task.selectedMaintenanceTask.workOrder.startedOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                                                <p>Estado de la Orden de trabajo: <span className="badge bg-gray ms-5px">{t("workOrder.state_" + task.selectedMaintenanceTask.workOrder.stateNormalizedName)}</span></p>
                                                                <p>{t("common.created_by")}: {task.selectedMaintenanceTask.workOrder.createdBy}</p>
                                                                {/* <div className="d-flex align-items-center mb-10px">
                                                                <div className="ms-auto">
                                                                    <Link to={"/workorder/detail?id=" + task.selectedMaintenanceTask.workOrder.id} className="btn btn-primary me-5px">Ver Orden de trabajo</Link>
                                                                </div>
                                                            </div> */}
                                                                {task.selectedMaintenanceTask.workOrder.logTracks &&
                                                                    <div className="col-xl-12">
                                                                        {task.selectedMaintenanceTask.workOrder.logTracks.length > 0 &&
                                                                            <Panel>
                                                                                <PanelHeader noButton={true}>
                                                                                    <div className="d-flex align-items-center">
                                                                                        Historial
                                                                                    </div>
                                                                                </PanelHeader>

                                                                                <PanelBody>
                                                                                    {task.selectedMaintenanceTask.workOrder.logTracks.map((log, index) =>
                                                                                        <div key={index}>
                                                                                            <p><i className="fa fa-circle-info fa-lg"></i>&nbsp;{moment(log.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm:ss")}&nbsp;-&nbsp;{log.createdByFullName}</p>
                                                                                            <p>{log.body}</p>
                                                                                        </div>
                                                                                    )}
                                                                                </PanelBody>
                                                                            </Panel>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </PanelBody>
                                                <PanelFooter>
                                                    <div className="d-flex align-items-center">
                                                        <div className="ms-auto">
                                                            {task.selectedMaintenanceTask.stateId === MaintenanceTasksIdPending &&
                                                                <button className="btn btn-primary me-5px" onClick={() => toggleModal('modalAddTask', task.selectedMaintenanceTask)}>{t("common.start")}</button>
                                                            }
                                                            {/* VALIDATE Y FINISH */}
                                                            {/* {task.selectedMaintenanceTask.workOrder && task.selectedMaintenanceTask.stateId === MaintenanceTasksIdExecution &&
                                                                task.selectedMaintenanceTask.workOrder.workOrderStateId != WorkOrderStateToValidateId &&
                                                                <button className="btn btn-primary me-5px" onClick={() => toggleSweetAlert("sweetAlertToValidate")}>{t("common.sent_to_validate")}</button>
                                                            }
                                                            {task.selectedMaintenanceTask.workOrder && ((task.selectedMaintenanceTask.stateId === MaintenanceTasksIdExecution &&
                                                                !task.selectedMaintenanceTask.requireValidation) ||
                                                                (task.selectedMaintenanceTask.stateId === MaintenanceTasksIdExecution &&
                                                                    task.selectedMaintenanceTask.workOrder.workOrderStateId === WorkOrderStateToValidateId)) &&
                                                                <button className="btn btn-primary me-5px" onClick={() => toggleSweetAlert("sweetAlertFinish")} >{t("common.finish")}</button>
                                                            } */}
                                                            {(user.selectedTenant.agentRoles.toUpperCase() === "MANAGER" ||
                                                                task.selectedMaintenanceTask.createdById === user.selectedTenant.agentId) &&
                                                                <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteTask') }}> Eliminar <i className="fa fa-trash"></i> </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    {task.error && <div className='text-center mt-5px'>
                                                        <span className='text-danger'>Ha ocurrido un problema: {task.errorMessage}</span></div>}
                                                        {workOrder.error && <div className='text-center mt-5px'>
                                                            <span className='text-danger'>Ha ocurrido un problema: {workOrder.errorMessage}</span></div>}
                                                </PanelFooter>
                                            </Panel>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2">

                                {task.selectedMaintenanceTask.scheduleIterations &&
                                    <div>
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t("common.scheduled_dates")}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {task.selectedMaintenanceTask.scheduleIterations.map((iteration, index) =>
                                                    iteration.workOrderId ?
                                                        <p key={index} className={iteration.scheduleState === "ACTIVE" ? "bg-gray-300 p-2px" : ""}>
                                                            <Link to={"/workorder/detail?id=" + iteration.workOrderId} key={index} className={iteration.scheduleState === "ACTIVE" ? "bg-gray-300 p-2px" : ""}>{moment(iteration.scheduledDate).format("DD/MM/YYYY")}&nbsp;{t("scheduleState.state_" + iteration.scheduleState).toUpperCase()}</Link>
                                                        </p>
                                                        :
                                                        <p key={index} className={iteration.scheduleState === "ACTIVE" ? "bg-gray-300 p-2px" : ""}>{moment(iteration.scheduledDate).format("DD/MM/YYYY")}&nbsp;{t("scheduleState.state_" + iteration.scheduleState).toUpperCase()}</p>
                                                )}
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                            </div>

                        </div>

                        <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                        {task.selectedMaintenanceTask.taskReviewFiles &&
                            <div className='mb-10px'>
                                <Viewer files={task.selectedMaintenanceTask.taskReviewFiles} allowDelete={false} deleteAsyncCallback={deleteFile} />
                            </div>
                        }
                        <Viewer files={task.selectedMaintenanceTask.files} allowDelete={false} deleteAsyncCallback={deleteFile} />

                        <Modal isOpen={modalViewDeleteTask} toggle={() => toggleModal('modalViewDeleteTask')} size="md" style={{ maxWidth: '300px', width: '100%' }}>
                            <ModalHeader toggle={() => toggleModal('modalViewDeleteTask')}>Eliminar Activo</ModalHeader>
                            <ModalBody>
                                <span>¿Esta seguro que desea eliminar la tarea <b>{task.selectedMaintenanceTask.description}</b>, creado por <b>{task.selectedMaintenanceTask.createdBy}</b> el <b>{moment(task.selectedMaintenanceTask.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteTask(task.selectedMaintenanceTask.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteTask')}>No</button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={modalAddTask} toggle={() => toggleModal('modalAddTask')}>
                            <ModalHeader toggle={() => toggleModal('modalAddTask')}>{t("task.create_workOrder")} </ModalHeader>
                            <ModalBody>
                                <form onSubmit={handleSubmit(startMaintenanceTask)}>

                                    {/* <input type="hidden" id="id" {...register("id", {
                                        value: task.selectedMaintenanceTask.id
                                    })} />

                                    <input type="hidden" id="scheduleId" {...register("scheduleId", {
                                        value: task.selectedMaintenanceTask.scheduleId
                                    })} />

                                    <input type="hidden" id="scheduledDate" {...register("scheduledDate", {
                                        value: task.selectedMaintenanceTask.scheduledDate
                                    })} /> */}

                                    {user.fullList &&
                                        <div className="form-floating mb-15px">
                                            <select className="form-select" id="operatorId" {...register("operatorId", {
                                                required: true,
                                                value: task.selectedMaintenanceTask.responsibleId,
                                            })}>
                                                <option></option>
                                                {user.fullList.map((user, index) =>
                                                    (user.role.normalizedName.includes("MANAGER") ||
                                                        user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                        user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                    <option key={user.agent.id} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                )
                                                }
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t("workOrder.operator")}
                                            </label>
                                        </div>
                                    }

                                    <div className="mb-20px">
                                        <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value="Asignar" />
                                        {task.error && <div className='text-center'>
                                            <span className='text-danger'>{t("common.error", { message: task.errorMessage })}</span></div>}
                                    </div>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-white" onClick={() => toggleModal('modalAddTask')}>{t("common.cancel")}</button>
                                {/*<button className="btn btn-primary">Create</button>*/}
                            </ModalFooter>
                        </Modal>

                    </div>
                }
            </div >
        </Loader>
    )
};

export default MaintenanceTaskDetail;