import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { Panel, PanelHeader, PanelFooter, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { GetRoom, DeleteRoom } from '../../../services/redux/roomSlice';
import Upload from '../../../components/app/file/upload.js';
import { postfileData } from '../../../services/redux/fileSlice';
import { FileTypes, WorkOrderStateFinishedId } from '../../../utils/generalConstants';
import Viewer from '../../../components/app/file/viewer.js';
import { useTranslation } from "react-i18next";

const RoomDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const room = useSelector(state => state.roomReducer)

    const query = new URLSearchParams(window.location.search)

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);
    const [modalViewDeleteRoom, setModalViewDeleteRoom] = useState(false);

    const getRoomLocal = async () => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        await dispatch(GetRoom({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const deleteRoom = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteRoom({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/room/list");
        });
    }

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", room.selectedRoom.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'ROOM IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getRoomLocal();
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getRoomLocal();
    }, []);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            case 'modalViewDeleteRoom':
                setModalViewDeleteRoom(!modalViewDeleteRoom);
                break;
            default:
                break;
        }
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }

    return (
        <div>
            {room.selectedRoom &&
                <div>
                    <ol className="breadcrumb float-xl-end" hidden>
                        <li className="breadcrumb-item"><Link to="/dashboard">Inicio</Link></li>
                        <li className="breadcrumb-item"><Link to="/room/list">{t('common.rooms')}</Link></li>
                        <li className="breadcrumb-item active">{room.selectedRoom.name}</li>
                    </ol>
                    <h1 className="page-header">{room.selectedRoom.name}</h1>
                    {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                        user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link to="/room/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                {/* <Link to="/room/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-xl-6">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader noButton={true}>
                                        <div className="d-flex justify-content-between">
                                            {t("common.general_data")}
                                            {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                                <div>
                                                    <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill" to={{
                                                        pathname: "/room/edit",
                                                        state: {
                                                            room: room.selectedRoom
                                                        }
                                                    }}> {t("common.edit")}</Link>
                                                    <button
                                                        className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2"
                                                        onClick={() => toggleModal('modalAddFiles')}>
                                                        {t("common.add_files")}
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </PanelHeader>
                                    <PanelBody>
                                        <p>Disponible: {room.selectedRoom.isActive ? "Si" : "No"}</p>
                                        {room.selectedRoom.moreInfo &&
                                            <p>{t("common.aditional_info")}:<br />{room.selectedRoom.moreInfo}</p>
                                        }
                                        <p>{t("common.createdOn")}: {moment(room.selectedRoom.createdOn).add(user.selectedTenant.timeZone, 'hour').format("DD/MM/YYYY HH:mm")}</p>
                                        <p>{t("common.created_by")}: {room.selectedRoom.createdBy}</p>
                                    </PanelBody>

                                    <PanelFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ms-auto">
                                                {query.get("taskid") &&
                                                    <Link to={"/task/detail?id=" + query.get("taskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("servicetaskid") &&
                                                    <Link to={"/servicetask/detail?id=" + query.get("servicetaskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("routinetaskid") &&
                                                    <Link to={"/routinetask/detail?id=" + query.get("routinetaskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("workorderid") &&
                                                    <Link to={"/workorder/detail?id=" + query.get("workorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("serviceworkorderid") &&
                                                    <Link to={"/serviceworkorder/detail?id=" + query.get("serviceworkorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("routineworkorderid") &&
                                                    <Link to={"/routineworkorder/detail?id=" + query.get("routineworkorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {query.get("taskreviewid") &&
                                                    <Link to={"/taskreview/detail?id=" + query.get("taskreviewid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                }
                                                {(user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
                                                    user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF") &&
                                                    <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteRoom') }}> {t("common.delete")} <i className="fa fa-trash"></i> </button>
                                                }
                                            </div>
                                        </div>
                                    </PanelFooter>
                                </Panel>

                            </div>
                        </div>
                        {(room.selectedRoom.workOrders || room.selectedRoom.maintenanceTasks) &&
                            <div className="col-xl-3">
                                {room.selectedRoom.maintenanceTasks && room.selectedRoom.maintenanceTasks.length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t('task.scheduled_tasks_title')}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {room.selectedRoom.maintenanceTasks.map((task, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-tasks"></i> {task.description}</p>
                                                        <p>
                                                            <Link to={"/task/detail?id=" + task.id} key={index} >
                                                                {moment(task.scheduledDate).format("DD/MM/YYYY")}&nbsp;<span className="badge bg-gray ms-5px">{t("task.state_" + task.stateNormalizedName)}</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                                {room.selectedRoom.maintenanceTasks.length === 0 &&
                                                    <p>{t('task.scheduled_tasks_title')} - {t('common.no_records_found')}</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                                {room.selectedRoom.workOrders && room.selectedRoom.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t('dashboard.title_tasks_active')}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {room.selectedRoom.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).map((workorder, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                        <p>
                                                            <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}&nbsp;<span className="badge bg-gray ms-5px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                                {room.selectedRoom.workOrders.length === 0 &&
                                                    <p>{t('dashboard.title_tasks_active')} - {t('common.no_records_found')}</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                                {room.selectedRoom.workOrders && room.selectedRoom.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).length != 0 &&
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    {t('dashboard.title_tasks_finished')}
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {room.selectedRoom.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).map((workorder, index) =>
                                                    <div key={index}>
                                                        <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                        <p>
                                                            <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}&nbsp;<span className="badge bg-gray ms-5px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                                {room.selectedRoom.workOrders.length === 0 &&
                                                    <p>{t('dashboard.title_tasks_finished')} - {t('common.no_records_found')}</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                }
                            </div>
                        }

                        <div className="col-xl-3">
                            {room.selectedRoom.logTracks && room.selectedRoom.logTracks.length != 0 &&
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex align-items-center">
                                                Historial
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {room.selectedRoom.logTracks.map((log, index) =>
                                                <div key={index}>
                                                    <p><i className="fa fa-circle-info fa-lg"></i>&nbsp;{moment(log.createdOn).format("DD/MM/YYYY")}&nbsp;{log.CreatedByFullName}</p>
                                                    <p>{log.body}</p>
                                                </div>
                                            )}
                                            {room.selectedRoom.logTracks.length === 0 &&
                                                <p>No se registran historial de acciones.</p>
                                            }
                                        </PanelBody>
                                    </Panel>
                                </div>
                            }

                            {room.selectedRoom.assets && room.selectedRoom.assets.length != 0 &&
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex align-items-center">
                                                {t('menu.assets')}
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {room.selectedRoom.assets.map((asset, index) =>
                                                <div key={index}>
                                                    <p>
                                                        <span className="badge bg-gray ms-5px">{t("asset.type_" + asset.typeNormalizedName)}</span>
                                                        {!asset.isOutOfService && !asset.isBackup && <span className="badge bg-green ms-5px me-5px">{t("asset.in_service")}</span>}
                                                        {asset.isOutOfService && <span className="badge bg-red ms-5px me-5px">{t("asset.out_of_service")}</span>}
                                                    </p>
                                                    <p>
                                                        <i className="fa fa-cogs"></i> <Link to={"/asset/detail?id=" + asset.id} key={index} >
                                                            {asset.name}
                                                        </Link>
                                                    </p>
                                                </div>
                                            )}
                                            {room.selectedRoom.assets.length === 0 &&
                                                <p>{t('menu.assets')} - {t('common.no_records_found')}</p>
                                            }
                                        </PanelBody>
                                    </Panel>
                                </div>
                            }
                        </div>

                    </div>
                    <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>

                    <Viewer files={room.selectedRoom.files} />

                    <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteRoom} toggle={() => toggleModal('modalViewDeleteRoom')} >
                        <ModalHeader toggle={() => toggleModal('modalViewDeleteRoom')}>Eliminar Habitación</ModalHeader>
                        <ModalBody>
                            {!room.error &&
                                <span>¿Esta seguro que desea eliminar la habitación <b>{room.selectedRoom.name}</b>, creado por <b>{room.selectedRoom.createdBy}</b> el <b>{moment(room.selectedRoom.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                            }
                            {room.error && <div className='text-center'>
                                <span className='text-danger'>Ha ocurrido un problema: {room.errorMessage}</span></div>}
                        </ModalBody>
                        {!room.error &&
                            <ModalFooter>
                                <button className="btn btn-danger" onClick={() => deleteRoom(room.selectedRoom.id)}>Si, borrar!</button>
                                <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteRoom')}>No</button>
                            </ModalFooter>
                        }
                    </Modal>

                </div>
            }
        </div>
    )
};

export default RoomDetail;