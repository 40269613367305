import React, { useEffect } from 'react';
import NVD3Chart from 'react-nvd3';

export const DonutChart = ({ title, data, isDonut }) => {
    const chartData = data.map((item) => ({
        label: item.label,
        value: item.value,
        color: item.color, // Añadimos el color aquí
    }));

    useEffect(() => {
        const adjustLegendStyle = () => {
            const legendContainer = document.querySelector('.nvd3 .nv-legend');
            if (legendContainer) {
                legendContainer.style.whiteSpace = 'nowrap'; // Evitar salto de línea
                legendContainer.style.display = 'flex'; // Leyendas en fila
                legendContainer.style.justifyContent = 'center'; // Centrar
                legendContainer.style.alignItems = 'center'; // Alinear verticalmente
                legendContainer.style.gap = '10px'; // Espacio entre elementos
            }

            const series = document.querySelectorAll('.nvd3 .nv-legend .nv-series');
            series.forEach((el) => {
                el.style.display = 'flex';
                el.style.alignItems = 'center';
                el.style.gap = '5px';
            });
        };

        // Esperar a que el gráfico termine de renderizar
        setTimeout(adjustLegendStyle, 100);
    }, [chartData]);

    return (
        <div style={{ width: '400px', height: '300px' }}>
            <h3>{title}</h3>
            <NVD3Chart
                type="pieChart"
                datum={chartData}
                x="label"
                y="value"
                donut={isDonut}
                showLabels={true}
                donutRatio={0.5}
                showLegend={true}
                height={300}
                width={300}
                color={chartData.map((item) => item.color)} // Pasamos los colores aquí
            />
        </div>
    );
};

const DonutChart2 = ({ title, data, isDonut }) => {
    const chartData = data.map((item) => ({
        label: item.label,
        value: item.value,
        color: item.color, // Añadimos el color aquí
    }));

    return (
        <div style={{ width: '400px', height: '300px' }}>
            <h3>{title}</h3>
            <NVD3Chart
                type="pieChart"
                datum={chartData}
                x="label"
                y="value"
                donut={isDonut}
                showLabels={true}
                donutRatio={0.5}
                showLegend={true}
                height={300}
                width={300}
                color={chartData.map((item) => item.color)} // Pasamos los colores aquí
            />
        </div>
    );
};