import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Panel, PanelHeader, PanelBody } from '.././../../components/panel/panel.jsx';
import { AppSettings } from '../../../config/app-settings.js';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditMaintenanceTask } from '../../../services/redux/maintenanceTaskSlice';
import { postfileData } from '../../../services/redux/fileSlice';
import { FullListAssets } from '../../../services/redux/assetSlice';
import { FullListFunctionalUnits } from '../../../services/redux/functionalUnitSlice';
import { FullListRooms } from '../../../services/redux/roomSlice';
import { FullListAreas } from '../../../services/redux/areaSlice';
import { FullListSuppliers } from '../../../services/redux/supplierSlice';
import { MaintenanceTaskTypes, MaintenanceTaskPriorityLevel, HotelTenantType, PrivateNeighborhoodTenantType, WorkOrderStateStartedId } from '../../../utils/generalConstants'
import { Loader } from '../../widget/loader'
import Upload from '../../../components/app/file/upload.js';
import { FileTypes } from '../../../utils/generalConstants';
import { useTranslation } from "react-i18next";
import { renderAreasOptions } from '../../../components/app/area/common.js';
import { FullListUsers } from '../../../services/redux/userSlice.js';
import moment from 'moment';
import { Alert } from 'reactstrap';

const MaintenanceTaskEdit = () => {

    const location = useLocation();
    const { maintenanceTask } = location.state

    console.log(JSON.stringify(maintenanceTask, null, 2));

    const dispatch = useDispatch();
    const history = useHistory();
    const context = useContext(AppSettings);
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const task = useSelector(state => state.maintenanceTaskReducer)
    const assets = useSelector(state => state.assetReducer)
    const functionalUnits = useSelector(state => state.functionalUnitReducer)
    const rooms = useSelector(state => state.roomReducer)
    const areas = useSelector(state => state.areaReducer)
    const suppliers = useSelector(state => state.supplierReducer)

    const hasActiveWorkOrder = maintenanceTask.workOrder && maintenanceTask.workOrder.workOrderStateId === WorkOrderStateStartedId;
    //console.log(maintenanceTask);
    const [startDate, setStartDate] = useState(new Date(maintenanceTask.startDate));
    const [endDate, setEndDate] = useState(new Date(maintenanceTask.endDate ?? maintenanceTask.endDate));
    // const [scheduleCheckbox, setScheduleCheckbox] = useState(maintenanceTask.scheduleIterations.length != 1); //Once
    const [scheduleCheckbox, setScheduleCheckbox] = useState(maintenanceTask.scheduleTypeId != 1); //1 = Once
    const [requireValidation, setRequireValidation] = useState(maintenanceTask.requireValidation);
    const [modalAddFiles, setModalAddFiles] = useState(false);

    const [autoStartDisabled, setAutoStartDisabled] = useState(maintenanceTask.responsibleId === null);
    const [autoStart, setAutoStart] = useState(maintenanceTask.autoStart);

    const [selectAreaId, setSelectAreaId] = useState(maintenanceTask.areaId);
    const [assetsRelatedToSelectedArea, setAssetsRelatedToSelectedArea] = useState(null);
    const [selectedAssetId, setSelectedAssetId] = useState(maintenanceTask.assetId);

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);

        !user.fullList &&
            dispatch(FullListUsers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !assets.fullList &&
            dispatch(FullListAssets({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !functionalUnits.fullList &&
            dispatch(FullListFunctionalUnits({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !rooms.fullList &&
            dispatch(FullListRooms({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            })
        !areas.fullList &&
            dispatch(FullListAreas({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

        !suppliers.fullList &&
            dispatch(FullListSuppliers({ authToken: user.authToken, tenantId: user.selectedTenant.id })).then((response) => {
                //console.log(response);
            });

    }, []);

    useEffect(() => {

        if (selectAreaId && assets.fullList) {
            const assetsRelatedToSelectedArea = assets.fullList.filter(a => a.areaId === selectAreaId);
            if (assetsRelatedToSelectedArea.length > 0) {
                setAssetsRelatedToSelectedArea(assetsRelatedToSelectedArea);
                // console.log("selectAreaId");
                // console.log(selectAreaId);
            } else {
                setAssetsRelatedToSelectedArea(null);
                // console.log("selectAreaId else");
                // console.log(selectAreaId);
            }
        }

    }, [selectAreaId, assets.fullList]);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        let requestData = {
            "id": maintenanceTask.id,
            "tenantId": user.selectedTenant.id,
            "typeId": data.taskTypeId,
            "description": data.description,
            "detail": data.detail,
            "responsibleId": (data.operatorId != "") ? data.operatorId : null,
            "autoStart": autoStart,
            "priorityLevelId": data.priorityLevelId,
            "areaId": (data.areaId != "") ? data.areaId : null,
            "assetId": (data.assetId != "") ? data.assetId : null,
            "functionalUnitId": (data.functionalUnitId != "") ? data.functionalUnitId : null,
            "roomId": (data.roomId != "") ? data.roomId : null,
            "supplierId": data.supplierId ? data.supplierId : null,
            "estimatedCost": (data.estimatedCost != "") ? data.estimatedCost : null,
            "estimatedTime": (data.estimatedTime != "") ? data.estimatedTime : null,
            "maintenanceTaskStateId": maintenanceTask.stateId,
            "schedule": {
                "scheduleTypeId": (data.scheduleTypeId != undefined && data.scheduleTypeId != "") ? data.scheduleTypeId : 1,
                "interval": (data.interval != undefined && data.interval != "") ? data.interval : 1,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": (endDate != null) ? moment(endDate).format("YYYY-MM-DD") : moment(startDate).format("YYYY-MM-DD"),
                "createdById": user.selectedTenant.agentId,
            },
            "createdById": user.selectedTenant.agentId,
        }
        //console.log(startDate);
        // console.log(requestData);
        await dispatch(EditMaintenanceTask({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload && response.payload.data &&
                history.push("/task/detail?id=" + maintenanceTask.id);
        });
    };

    const onUploadSubmit = async (data) => {

        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", maintenanceTask.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'MANTENANCETASK IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            response && response.payload && response.payload.data &&
                history.push("/task/detail?id=" + response.payload.data.referenceId);
        });
    };

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            default:
                break;
        }
    }



    if (!user.authToken) {
        return <Redirect to='/user/login' />;
    }

    return (
        <Loader isLoading={task.isFetching}>
            <div>
                <ol className="breadcrumb float-xl-end" hidden>
                    <li className="breadcrumb-item"><Link to="/task/dashboard">{t('menu.tasks_dashboard')}</Link></li>
                    <li className="breadcrumb-item"><Link to={"/task/detail?id=" + maintenanceTask.id}>{maintenanceTask.description}</Link></li>
                    <li className="breadcrumb-item active">{t('common.edit')} {t('menu.tasks')}</li>
                </ol>
                <h1 className="page-header">{t('common.edit')} {t('menu.tasks')} <small>{t('common.complete_required_fields')}</small></h1>

                <div className="d-flex align-items-center mb-2">
                    <div className="ms-auto">
                        <Link to="/task/dashboard" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-tasks me-1"></i> {t("menu.tasks_dashboard")}</Link>
                        <Link to="/task/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                        <Link hidden to="/task/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link>
                        <Link to={"/task/detail?id=" + maintenanceTask.id} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill ms-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-7">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex justify-content-between">
                                        {maintenanceTask.description}
                                        <button hidden
                                            className="btn btn-green btn-sm btn-rounded px-3 rounded-pill"
                                            onClick={() => toggleModal('modalAddFiles')}>
                                            {t('common.add_files')}
                                        </button>
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-floating mb-15px">
                                            <input type="text" maxLength={90} className={"form-control fs-15px" + (errors.description ? ' is-invalid' : '')}
                                                id="description" {...register("description", {
                                                    required: true,
                                                    value: maintenanceTask.description
                                                })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.description')}
                                            </label>
                                            {errors.description && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <textarea rows="5" maxLength={2000} className={"form-control fs-15px h-100" + (errors.detail ? ' is-invalid' : '')}
                                                id="detail" {...register("detail", {
                                                    required: false,
                                                    value: maintenanceTask.detail,
                                                })} />
                                            <label htmlFor="floatingInput" className="fs-13px">
                                                {t('common.detail')}
                                            </label>
                                            {errors.detail && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        </div>

                                        {user.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="operatorId" {...register("operatorId", {
                                                    required: false,
                                                    value: maintenanceTask.responsibleId,
                                                })}
                                                    onChange={(e) =>
                                                    (
                                                        // console.log(e.target.value),
                                                        (e.target.value === "") ?
                                                            (setAutoStartDisabled(true), setAutoStart(false))
                                                            :
                                                            (setAutoStartDisabled(false))
                                                    )
                                                    }
                                                >
                                                    <option></option>
                                                    {user.fullList.map((user, index) =>
                                                        (user.role.normalizedName.includes("MANAGER") ||
                                                            user.role.normalizedName.includes("MAINTENANCECHIEF") ||
                                                            user.role.normalizedName.includes("MAINTENANCEOPERATOR")) &&
                                                        <option key={user.agent.id} value={user.agent.id}>{user.agent.fullName} ({t("roles.rol_" + user.role.normalizedName)})</option>
                                                    )
                                                    }
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    Asignar a{/* {t("workOrder.operator")} */}
                                                </label>
                                            </div>
                                        }

                                        {/* <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">Iniciar automáticamente en la fecha programada</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="app-header-inverse" onChange={(e) => setAutoStart(e.target.checked)} id="appHeaderInverse" checked={autoStart} disabled={autoStartDisabled} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div> */}

                                        {(hasActiveWorkOrder) &&
                                            <Alert color="warning" isOpen={true}>
                                                Hay un orden de trabajo activa. No se puede modificar los campos de fechas o recurrencia de la tarea.
                                            </Alert>
                                        }
                                        <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">{t('task.recurring_task')}</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input disabled={hasActiveWorkOrder} type="checkbox" className="form-check-input" name="app-header-inverse" onChange={(e) => setScheduleCheckbox(e.target.checked)} id="appHeaderInverse" checked={scheduleCheckbox} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div>

                                        {!scheduleCheckbox ?
                                            <div className="form-floating mb-15px">
                                                <DatePicker disabled={hasActiveWorkOrder} locale="es" dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={startDate} onChange={(date) => setStartDate(date)} required={!scheduleCheckbox} />
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                    {t('common.date')} {/* {startDate.toString()}  */}
                                                </label>
                                            </div>
                                            :
                                            <div>
                                                <div className="bg-blue-100 mb-15px p-15px">
                                                    <div className="form-floating mb-15px">
                                                        <DatePicker disabled={hasActiveWorkOrder} dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={startDate} onChange={(date) => setStartDate(date)} required={scheduleCheckbox} />
                                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                            {t('common.from')} {/* {startDate.toString()} */}
                                                        </label>
                                                    </div>
                                                    <div className="form-floating mb-15px">
                                                        <DatePicker disabled={hasActiveWorkOrder} dateFormat="dd-MM-yyyy" className="form-control fs-15px pt-25px" selected={endDate} onChange={(date) => setEndDate(date)} required={scheduleCheckbox} />
                                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-11px pt-1px mb-10px pb-30px">
                                                            {t('common.to')} {/* {endDate.toString()} */}
                                                        </label>
                                                    </div>
                                                    <div className="form-floating mb-15px">

                                                        <select disabled={hasActiveWorkOrder} className="form-select" id="scheduleTypeId" {...register("scheduleTypeId", {
                                                            required: { scheduleCheckbox },
                                                            value: maintenanceTask.scheduleTypeId
                                                        })}>
                                                            <option></option>
                                                            <option value="2" key="2">{t('common.daily')} </option>
                                                            <option value="3" key="3">{t('common.weekly')} </option>
                                                            <option value="4" key="4">{t('common.monthly')} </option>
                                                        </select>
                                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                            {t('common.frequency')}
                                                        </label>
                                                    </div>
                                                    <div className="form-floating">
                                                        <input disabled={hasActiveWorkOrder} type="number" pattern="[0-365]*" className="form-control fs-15px" id="interval" defaultValue="1" {...register("interval", {
                                                            required: { scheduleCheckbox },
                                                            value: maintenanceTask.interval
                                                        })} />
                                                        <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                            {t('common.interval')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="form-floating mb-15px">
                                            <select className={"form-select" + (errors.taskTypeId ? ' is-invalid' : '')}
                                                id="taskTypeId" {...register("taskTypeId", {
                                                    required: true,
                                                    value: maintenanceTask.taskTypeId
                                                })}>
                                                <option></option>
                                                {MaintenanceTaskTypes.map(o => <option key={o.id} value={o.id}>{t("task.type_" + o.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.type')}
                                            </label>
                                            {errors.taskTypeId && <span className='invalid-feedback'>{t('validation.required_field')}  </span>}
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <select className={"form-select" + (errors.priorityLevelId ? ' is-invalid' : '')} id="priorityLevelId" {...register("priorityLevelId", {
                                                required: true,
                                                value: maintenanceTask.priorityLevelId
                                            })}>
                                                <option></option>
                                                {MaintenanceTaskPriorityLevel.map(o => <option key={o.id} value={o.id}>{t("common.priority_level_" + o.NormalizeName)}</option>)}
                                            </select>
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.priority')}
                                            </label>
                                            {errors.priorityLevelId && <span className='invalid-feedback'>{t('validation.required_field')}</span>}
                                        </div>

                                        <div className="row mb-10px align-items-center">
                                            <div className="col-8 control-label text-dark fw-bold">Requiere control de validación</div>
                                            <div className="col-4 d-flex">
                                                <div className="form-check form-switch ms-auto mb-0">
                                                    <input type="checkbox" className="form-check-input" name="RequireValidation" onChange={(e) => setRequireValidation(e.target.checked)} id="RequireValidation" checked={requireValidation} />
                                                    <label className="form-check-label" htmlFor="appHeaderInverse">&nbsp;</label>
                                                </div>
                                            </div>
                                        </div>

                                        {areas.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="areaId" {...register("areaId", {
                                                    required: false,
                                                    value: maintenanceTask.areaId
                                                })}
                                                    onChange={(e) => setSelectAreaId(e.target.value)}
                                                >
                                                    <option></option>
                                                    {/* {areas.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)} */}
                                                    {renderAreasOptions(areas.fullList, null)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.area')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.tenantTypeId == PrivateNeighborhoodTenantType &&
                                            functionalUnits.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="functionalUnitId" {...register("functionalUnitId", {
                                                    required: false,
                                                    value: maintenanceTask.functionalUnitId
                                                })}>
                                                    <option></option>
                                                    {functionalUnits.fullList.map(a => <option key={a.id} value={a.id}>{a.number}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.functional_unit')}
                                                </label>
                                            </div>
                                        }

                                        {user?.selectedTenant?.tenantTypeId == HotelTenantType &&
                                            rooms.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="roomId" {...register("roomId", {
                                                    required: false,
                                                    value: maintenanceTask.roomId
                                                })}>
                                                    <option></option>
                                                    {rooms.fullList.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.room')}
                                                </label>
                                            </div>
                                        }

                                        {assets.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="assetId" {...register("assetId", {
                                                    required: false,
                                                    value: maintenanceTask.assetId
                                                })}
                                                    onChange={(e) => setSelectedAssetId(e.target.value)}
                                                    value={selectedAssetId}
                                                >
                                                    <option key="select" value=""></option>
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="related" value=""># Relacionados al area #</option>
                                                    }
                                                    {assetsRelatedToSelectedArea && assetsRelatedToSelectedArea.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="none" value=""></option>
                                                    }
                                                    {assetsRelatedToSelectedArea &&
                                                        <option key="all" value=""># Todos #</option>
                                                    }
                                                    {assets.fullList.map(a => <option key={"all" + a.id} value={a.id}>{a.name}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.asset')}
                                                </label>
                                            </div>
                                        }

                                        {suppliers.fullList &&
                                            <div className="form-floating mb-15px">
                                                <select className="form-select" id="supplierId" {...register("supplierId", {
                                                    required: false,
                                                    value: maintenanceTask.supplierId
                                                })}>
                                                    <option></option>
                                                    {suppliers.fullList.map(s => <option key={s.id} value={s.id}>{s.fullName}</option>)}
                                                </select>
                                                <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                    {t('common.supplier')}
                                                </label>
                                            </div>
                                        }
                                        <div className="form-floating mb-15px">
                                            <input type="text" pattern="[+-]?\d+(?:[.]\d+)?" className={"form-control fs-15px" + (errors.estimatedCost ? ' is-invalid' : '')}
                                                id="estimatedCost" {...register("estimatedCost", {
                                                    required: false,
                                                    value: maintenanceTask.estimatedCost
                                                })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.estimated_cost')}
                                            </label>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <input type="number" pattern="[0-999]*" className={"form-control fs-15px" + (errors.estimatedTime ? ' is-invalid' : '')}
                                                id="estimatedTime" {...register("estimatedTime", {
                                                    required: false,
                                                    value: maintenanceTask.estimatedTime && maintenanceTask.estimatedTime
                                                })} />
                                            <label htmlFor="floatingInput" className="d-flex align-items-center fs-13px">
                                                {t('common.estimated_time')} (horas)
                                            </label>
                                        </div>

                                        <div className="mb-20px">
                                            <input type="submit" className="btn btn-primary d-block w-100 h-45px btn-lg" value={t("common.save")} />
                                            {task.error && <div className='text-center'>
                                                <span className='text-danger'>{t("common.error", { message: task.errorMessage })}</span></div>}
                                        </div>

                                    </form>

                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    <div className="col-xl-3" hidden>
                        <div className="col-xl-12" hidden>
                            <Panel>
                                <PanelHeader noButton={true}>
                                    <div className="d-flex align-items-center">
                                        {t('common.aditional_info')}
                                    </div>
                                </PanelHeader>
                                <PanelBody>
                                    <p>Content</p>
                                </PanelBody>
                            </Panel>
                        </div>
                        <Upload onSubmit={onUploadSubmit} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>
                    </div>
                </div>
            </div>
        </Loader >
    )
};

export default MaintenanceTaskEdit;